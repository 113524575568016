/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useState } from "react";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { Dispatch } from "@reduxjs/toolkit";
import { ActionButton, DefaultButton, IIconProps, MessageBar, MessageBarType, PrimaryButton, Spinner, SpinnerSize, Text } from "@fluentui/react";
import {
    addAccessRestrictedUsers,
    cicSelected,
    getWizardRecordInformationDataAsync,
    removeAccessRestrictedUsers,
    selectRestrictAccessTeams,
    selectSelectedAccessRestrictedUsers,
    selectSelectedOverrideDefaultAccess,
    selectSelectedRestrictAccessTeams,
    setOverrideDefaultAccess,
    setSelectedRestrictTeams,
    setWizardRecordInformation
} from "../wizardRecordInformation/wizardRecordInformationSlice";
import {
    getISelectOptionPropsFromIOrgLevel,
} from "../wizardRecordInformation/WizardRecordInformation";
import {
    selectCurrentRecord,
    updateRecordInformationAsync
} from "../manageRecordWrapper/manageRecordWrapperSlice";
import { IRecord, ITerm, IUser, IUserFieldActivity } from "../../services/Api/executor/IApiServiceExecutor";
import {
    accessDescipritonHeader,
    accessDescriptionBlurb1,
    //accessDescriptionBlurb2,
    accessDescriptionBlurbStrong,
    buttonLabelAdd,
    commentsEditAccess,
    descriptionCommentsEditAccess,
    fieldDescriptionRestrictAccessTeam,
    //fieldDescriptionViewAccessRestricted,
    fieldDescrpitionOverideAccess,
    fieldLabelOverideAccess,
    fieldLabelRestrictAccessTeam,
    fieldLabelViewAccessRestricted,
    headerAccess,
    headerAccessCloseButton,
    headerAccessShowButton,
    headerDescription1,
    headerDescription2,
    headerDescriptionBoldOpen,
    headerDescriptionBoldRestricted
} from "../../providers/Constants/RecordWizardConstants";
import {
    DateAccessColumnName,
    JobTitleColumnName,
    NameColumnName,
    OrganisationColumnName
} from "../../providers/Constants/FormControlConstants";
import UserList from "../../components/FormControls/UserList/UserList";
import Select, { ISelectOptionProps } from "../../components/FormControls/Select/Select";
import PeoplePicker from "../../components/FormControls/PeoplePicker/PeoplePicker";
import { FieldWrapper } from "../../components/FormControls/FieldWrapper/FieldWrapper";
import Checkbox from "../../components/FormControls/Checkbox/Checkbox";
import styles from "../wizardRecordInformation/WizardRecordInformation.module.scss";
import TextBox from "../../components/FormControls/TextBox/TextBox";
import { emptyTerm, teams } from "../../providers/Constants/TermConstants";
import ArrayHelper from "../../helpers/ArrayHelper";
import DependencyResolver from "../../providers/DependencyResolver/DependencyResolver";
import IApiService from "../../services/Api/IApiService";

interface IAccessPermissionsProps {
    isMobileView: boolean;
    isCreateRecordWizard: boolean;
}
// BUGGY SELECT COMPONENT IN MULTY MODE
let restrictAccessTeamsRef: Select | null = null;
export default function AccessPermissions({
    isMobileView,
    isCreateRecordWizard
}: IAccessPermissionsProps): JSX.Element | null {
    const currentRecord: IRecord | null = useSelector(selectCurrentRecord);
    const selectedOverrideDefaultAccess: boolean = useSelector(selectSelectedOverrideDefaultAccess);
    const isCICSelected: boolean = useSelector(cicSelected);
    const restrictAccessTeams: ITerm[] = useSelector(selectRestrictAccessTeams);
    const selectedRestrictAccessTeams: ITerm[] | null = useSelector(
        selectSelectedRestrictAccessTeams
    );
    const [accessRestrictedUsersHistory, setAccessRestrictedUsersHistory] = useState<IUserFieldActivity[] | null>(null);

    const accessRestrictedUsers: IUser[] = useSelector(selectSelectedAccessRestrictedUsers);

    const [accessExpanded, setAccessExpanded] = React.useState(selectedOverrideDefaultAccess || false);
    const [selectedUser, setSelectedUser] = React.useState<IUser | null>(null);
    const [changeComments, setChangeComments] = React.useState<string | undefined>(undefined);
    const [updatingInProgress, setUpdatingInProgress] = React.useState(false);
    const [error, setError] = React.useState<Error | undefined>();

    const readOnly: boolean = React.useMemo(
        () =>
            !isCreateRecordWizard && !currentRecord?.isCurrentUserRecordAdmin &&
            (!currentRecord?.canCurrentUserEditRecord || !!currentRecord?.canCurrentUserEditRecordLimitedAccess || !!currentRecord?.noFurtherAction || !!currentRecord?.withdrawn),
        [
            currentRecord?.canCurrentUserEditRecordLimitedAccess,
            currentRecord?.canCurrentUserEditRecord,
            currentRecord?.noFurtherAction,
            currentRecord?.withdrawn,
            isCreateRecordWizard,
            currentRecord?.isCurrentUserRecordAdmin
        ]
    );

    const formIsBlocked: boolean = React.useMemo(() => {
        return readOnly || updatingInProgress;
    },[readOnly, updatingInProgress]);

    const anyChangesApplied: boolean = React.useMemo(() => {
        const isRestrictAccessTeamsUpdated: boolean = !!currentRecord && !ArrayHelper.isEqualAsJSON(currentRecord.restrictAccessTeams, selectedRestrictAccessTeams);
        const isAccessRestrictedUsersUpdated: boolean = !!currentRecord && !ArrayHelper.isEqualAsJSON(currentRecord.viewAccessUsers, accessRestrictedUsers);
        const isValuesUpdated:boolean = (isRestrictAccessTeamsUpdated || isAccessRestrictedUsersUpdated);

        
        return (
            !!currentRecord &&
            //if OverrideDefaultAccess disabled
            ((currentRecord.overrideDefaultAccess !== selectedOverrideDefaultAccess &&
                !selectedOverrideDefaultAccess) ||
                //if OverrideDefaultAccess activated
                (currentRecord.overrideDefaultAccess !== selectedOverrideDefaultAccess &&
                    selectedOverrideDefaultAccess &&
                    //rules is not empty but they match
                    (!!selectedRestrictAccessTeams?.length ||
                        !!accessRestrictedUsers?.length ||
                        //rules should not be empty and should not match
                        ((!!selectedRestrictAccessTeams?.length ||
                            !!accessRestrictedUsers?.length) &&
                            isValuesUpdated))) ||
                //if isRestrictAccessTeamsUpdated updated without updating OverrideDefaultAccess
                isRestrictAccessTeamsUpdated ||
                //if isAccessRestrictedUsersUpdated updated without updating OverrideDefaultAccess
                isAccessRestrictedUsersUpdated)
        );
    }, [
        currentRecord,
        accessRestrictedUsers,
        selectedOverrideDefaultAccess,
        selectedRestrictAccessTeams
    ]);
    
    const requiredFieldsIsNotEmpty: boolean = React.useMemo(() => {
        return !!changeComments;
    }, [changeComments]);

    const updateBtnIsActive: boolean = React.useMemo(() => {
        return anyChangesApplied && !formIsBlocked && requiredFieldsIsNotEmpty;
    },[anyChangesApplied, formIsBlocked, requiredFieldsIsNotEmpty]);

    const cancelBtnIsActive: boolean = React.useMemo(() => {
        return anyChangesApplied && !formIsBlocked;
    },[anyChangesApplied, formIsBlocked]);

    const isCabinetAccessTeamsInRecord: boolean = React.useMemo(() => {
        const cobinetTermIndex: number | undefined = currentRecord?.restrictAccessTeams?.findIndex(r => r.name === teams.cabinet);
        return cobinetTermIndex !== undefined ? cobinetTermIndex  >= 0 : false;
    },[currentRecord]);

    const overrideDefaultAccessDisabled: boolean = React.useMemo(() => {
        return formIsBlocked || isCICSelected;
    },[isCICSelected, formIsBlocked]);

    const prevIsCICSelectedRef: React.MutableRefObject<boolean|undefined> = React.useRef<boolean>();
    const prevIsCICSelected:boolean | undefined = prevIsCICSelectedRef.current;

    // 4. GET DISPATCH
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const dispatch: Dispatch<any> = useDispatch();

    const addIconProps: IIconProps = { iconName: "Add" };

    const { recordId } = useParams<{ recordId: string }>();

    React.useEffect(() => {
        const fetchUserHistory = async () => {
            if (accessRestrictedUsersHistory == null && recordId) {
                setAccessRestrictedUsersHistory([]);
                const resolver: DependencyResolver = new DependencyResolver();
                const apiService: IApiService = resolver.ResolveIApiService();
                const userHistory: IUserFieldActivity[] | null = await apiService.GetAccessRestrictedUsersHistory(recordId);    
                setAccessRestrictedUsersHistory(userHistory || []);
            }
        };
        fetchUserHistory();
    }, [accessRestrictedUsers, accessRestrictedUsersHistory, dispatch, recordId]);    

    React.useEffect(() => {
        if(currentRecord?.recordType){
            dispatch(getWizardRecordInformationDataAsync(currentRecord?.recordType));
        }
    }, [dispatch, currentRecord?.recordType]);

    React.useEffect(() => {
        if (!isCreateRecordWizard && currentRecord && recordId) {
            dispatch(setWizardRecordInformation(currentRecord));
        }
    }, [dispatch, currentRecord, recordId, isCreateRecordWizard]);

    const restrictAccessTeamsOptions: ISelectOptionProps[] = React.useMemo(() => {
        return restrictAccessTeams?.map(r => {
            if(r.name === teams.cabinet){
                return {
                    key: r.name || "",
                    text: r.name || "",
                    disabled: recordId && !currentRecord?.isCurrentUserRecordAdmin && isCabinetAccessTeamsInRecord
                } as ISelectOptionProps;
            }
            return getISelectOptionPropsFromIOrgLevel(r);
        }) || [];
    },[restrictAccessTeams, recordId, currentRecord?.isCurrentUserRecordAdmin, isCabinetAccessTeamsInRecord]);
    
    React.useEffect(() => {
        prevIsCICSelectedRef.current = isCICSelected;
    },[isCICSelected]);
    

    React.useEffect(() => {
        if(isCICSelected && recordId === undefined){
            dispatch(setOverrideDefaultAccess(true));
            if(!selectedRestrictAccessTeams || selectedRestrictAccessTeams.findIndex(r => r.name === teams.cabinet) === -1){
                const restrictTeam: ITerm | undefined = restrictAccessTeams.find(r => r.name === teams.cabinet);
                dispatch(setSelectedRestrictTeams([...(selectedRestrictAccessTeams?.map(r => r.name || "") || []), (restrictTeam?.name || "")]));
            }
            setAccessExpanded(true);
        }
    }, [isCICSelected, dispatch, restrictAccessTeams, selectedRestrictAccessTeams, recordId]);

    React.useEffect(() => {
        if(!isCICSelected && recordId === undefined && (prevIsCICSelected === true)){
            dispatch(setSelectedRestrictTeams(undefined));
            dispatch(setOverrideDefaultAccess(false));
            setAccessExpanded(false);
        }
    }, [isCICSelected, dispatch, recordId, prevIsCICSelected]);

    const validateUpdateAccessPermissions: () => void = React.useCallback((): void => {
        if (recordId && updateBtnIsActive) {
            setUpdatingInProgress(true);
            setError(undefined);
            dispatch(
                updateRecordInformationAsync(
                    {
                        recordId: recordId,
                        comments: changeComments || "",
                        overrideDefaultAccess: selectedOverrideDefaultAccess || false,
                        restrictAccessTeams: selectedRestrictAccessTeams || [emptyTerm],
                        viewAccessUsers: accessRestrictedUsers || undefined
                    },
                    {
                        onSuccess: () => {
                            setUpdatingInProgress(false);
                        },
                        onError: err => {
                            setError(err);
                            setUpdatingInProgress(false);
                        }
                    }
                )
            );
        }
    }, [
        dispatch, 
        recordId, 
        updateBtnIsActive,
        selectedOverrideDefaultAccess, 
        selectedRestrictAccessTeams, 
        accessRestrictedUsers, 
        changeComments
    ]);
    
    const onCancelClick: () => void = React.useCallback(() => {
        if (!isCreateRecordWizard && currentRecord && recordId) {
            dispatch(setWizardRecordInformation(currentRecord));
            setChangeComments("");
            setSelectedUser(null);
            
            // BUGGY SELECT COMPONENT IN MULTY MODE
            if(restrictAccessTeamsRef && (restrictAccessTeamsRef as any).ddComponentRef){
                const toSelect: string[] = currentRecord.restrictAccessTeams?.map(t => t.name || "") || [];
                const defaultSelectedIdxs: number[] = [];
                restrictAccessTeamsOptions.forEach((o, index) => {
                    if(toSelect.filter(n => n === o.key).length){
                        defaultSelectedIdxs.push(index);
                    }
                });

                (restrictAccessTeamsRef as any).ddComponentRef.setState({
                    ... (restrictAccessTeamsRef as any).ddComponentRef.state,
                    selectedIndices: defaultSelectedIdxs
                });
            }
        }
    }, [dispatch, isCreateRecordWizard, currentRecord, recordId, restrictAccessTeamsOptions]);

    const restrictAccessTeamDisabled: boolean = !selectedOverrideDefaultAccess 
        || formIsBlocked || !restrictAccessTeamsOptions || restrictAccessTeamsOptions.length < 1;
    const viewAccessRestrictedDisabled: boolean = !selectedOverrideDefaultAccess || formIsBlocked;
    const viewAccessRestrictedBtnDisabled: boolean = !selectedOverrideDefaultAccess || formIsBlocked || !selectedUser;
    const commentsDisabled: boolean = formIsBlocked || !anyChangesApplied;

    return (
        <>
            {isCreateRecordWizard && (
                <div className={styles.accessPermissionsContainer}>
                    <div className={styles.accessHeader}>{headerAccess}</div>
                    <div className={styles.accesDescription}>
                        {headerDescription1} <strong>{!selectedOverrideDefaultAccess ? headerDescriptionBoldOpen : headerDescriptionBoldRestricted}</strong>{" "}
                        {!selectedOverrideDefaultAccess && headerDescription2}
                    </div>
                    <div className={styles.buttonContainer}>
                        <DefaultButton
                            text={headerAccessShowButton}
                            onClick={() => setAccessExpanded(true)}
                            allowDisabledFocus
                            disabled={accessExpanded}
                            className={styles.buttonStyling}
                            ariaLabel={`${headerAccessShowButton} button`}
                        />
                        <DefaultButton
                            text={headerAccessCloseButton}
                            onClick={() => setAccessExpanded(false)}
                            allowDisabledFocus
                            disabled={!accessExpanded}
                            className={styles.buttonStyling}
                            ariaLabel={`${headerAccessCloseButton} button`}
                        />
                    </div>
                </div>
            )}
            {(isCreateRecordWizard && accessExpanded || !isCreateRecordWizard) && <div className={styles.expandedAccessContainer}>
                {!!error && 
                    <div>
                        <MessageBar
                            isMultiline={true}
                            messageBarType={MessageBarType.error}
                        >
                            {error.message}
                        </MessageBar>
                    </div>
                }
                <div className={styles.expandedHeader}>
                    <h2 className={styles.expandedHeaderText}>{accessDescipritonHeader}</h2>
                </div>
                <div>
                    <div className={styles.expandedHeaderDescription}>{accessDescriptionBlurb1}</div>
                    {/*<div className={styles.expandedHeaderDescription}>{accessDescriptionBlurb2}</div>*/}
                    <div className={styles.expandedHeaderDescriptionStrong}>
                        {accessDescriptionBlurbStrong}
                    </div>
                </div>
                <div className={styles.expandedCheckboxContainer}>
                    <Checkbox
                        name={fieldLabelOverideAccess}
                        checked={selectedOverrideDefaultAccess}
                        required={false}
                        disabled={overrideDefaultAccessDisabled}
                        onChange={(ev, checked) => dispatch(setOverrideDefaultAccess(checked))}
                    />
                    {selectedOverrideDefaultAccess && (
                        <div className={styles.checkboxDescription}>
                            {fieldDescrpitionOverideAccess}
                        </div>
                    )}
                </div>
                <FieldWrapper description={fieldDescriptionRestrictAccessTeam}>
                    <Select
                        ref={r => (restrictAccessTeamsRef = r)}
                        name={fieldLabelRestrictAccessTeam}
                        options={restrictAccessTeamsOptions}
                        required={false}
                        disabled={restrictAccessTeamDisabled}
                        defaultSelectedKey={
                            selectedRestrictAccessTeams?.map(t => t.name || "") || undefined
                        }
                        showAsLoading={restrictAccessTeamsOptions.length < 1}
                        onChange={() => {
                            // BUGGY SELECT COMPONENT IN MULTY MODE
                            if (restrictAccessTeamsRef) {
                                const options:
                                    | ISelectOptionProps[]
                                    | null = restrictAccessTeamsRef.getValue();
                                dispatch(setSelectedRestrictTeams(options?.map(option => option.key)));
                            }
                        }}
                        isMultiSelect={true}
                    />
                </FieldWrapper>
                <div className={styles.expandedNominationContainer}>
                    {/* SANDEEP Changes <FieldWrapper>
                        <div>{fieldDescriptionViewAccessRestricted}</div>
				</FieldWrapper> */}
                    <div>
                        <div className={styles.peoplePicker}>
                            <PeoplePicker
                                selectedUsers={selectedUser ? [selectedUser] : undefined}
                                onChange={(users: IUser[] | undefined) => {
                                    setSelectedUser((users || [])[0]);
                                }}
                                label={fieldLabelViewAccessRestricted}
                                disabled={viewAccessRestrictedDisabled}
                            />
                        </div>
                        <PrimaryButton
                            text={buttonLabelAdd} // To do: Place in constants}
                            onClick={() => {
                                if (selectedUser) {
                                    if (
                                        accessRestrictedUsers.map(u => u.graphUserId).indexOf(selectedUser.graphUserId) < 0 
                                        || accessRestrictedUsers.map(u => u.siteUserId).indexOf(selectedUser.siteUserId) < 0
                                    ) {
                                        dispatch(addAccessRestrictedUsers(selectedUser));
                                    }
                                }
                                setSelectedUser(null);
                            }}
                            allowDisabledFocus
                            iconProps={addIconProps}
                            className={styles.addButton}
                            disabled={viewAccessRestrictedBtnDisabled}
                        />
                    </div>
                    {accessRestrictedUsers.length ? (
                        !isMobileView ? (
                            <div className={styles.listContainer}>
                                <UserList users={accessRestrictedUsers} usersHistory={accessRestrictedUsersHistory} readOnly={readOnly} />
                            </div>
                        ) : (
                            <div className={styles.usersCardContainer}>
                                {accessRestrictedUsers.map((userItem, index) => {
                                    return (
                                        <div
                                            className={styles.userCardItemContainer}
                                            key={userItem.siteUserId || index}
                                        >
                                            {userItem.displayName && (
                                                <div className={styles.fieldUserInfo}>
                                                    {NameColumnName}
                                                    <div
                                                        className={[
                                                            styles.userInfoText,
                                                            styles.userName
                                                        ].join(" ")}
                                                    >
                                                        {userItem.displayName}
                                                    </div>
                                                </div>
                                            )}
                                            {userItem.orgLevel2 ||
                                                userItem.orgLevel3 ||
                                                (userItem.orgLevel4 && (
                                                    <div className={styles.fieldUserInfo}>
                                                        {OrganisationColumnName}
                                                        <div className={styles.userInfoText}>
                                                            {[
                                                                userItem.orgLevel2,
                                                                userItem.orgLevel3,
                                                                userItem.orgLevel4
                                                            ].join(",")}
                                                        </div>
                                                    </div>
                                                ))}
                                            {userItem.jobTitle && (
                                                <div className={styles.fieldUserInfo}>
                                                    {JobTitleColumnName}
                                                    <div className={styles.userInfoText}>
                                                        {userItem.jobTitle}
                                                    </div>
                                                </div>
                                            )}
                                            <div className={styles.fieldUserInfo}>
                                                {DateAccessColumnName}
                                                <div className={styles.userInfoText}>
                                                    {accessRestrictedUsersHistory?.find(u => u.userDisplayName === userItem.displayName)?.addedDateTime}
                                                </div>
                                            </div>
                                            <div className={styles.btnsArea}>
                                                <ActionButton 
                                                    className={styles.deleteBtn}
                                                    iconProps={{ iconName: "Delete" }} 
                                                    allowDisabledFocus
                                                    onClick={() => dispatch(removeAccessRestrictedUsers(userItem))}
                                                    ariaLabel={"Delete button"}
                                                >
                                                    Delete
                                                </ActionButton>
                                            </div>
                                        </div>
                                    );
                                })}
                            </div>
                        )
                    ) : (
                        <></>
                    )}
                </div>
                {recordId && (
                    <>
                        <div className={styles.commentsDescrContainer}>
                            <div className={styles.commentsDescrHeader}>
                                {commentsEditAccess}
                            </div>
                            <div className={styles.commentsDescrText}>
                                {descriptionCommentsEditAccess}
                            </div>
                        </div>
                        <FieldWrapper>
                            <TextBox
                                name={`${commentsEditAccess}`}
                                required={true}
                                rows={5}
                                value={changeComments}
                                onChange={(ev, newValue) => {
                                    setChangeComments(newValue || "");
                                }}
                                disabled={commentsDisabled}
                                valid={!commentsDisabled ? !!changeComments : true}
                            />
                        </FieldWrapper>
                    </>
                )}
                {recordId && !readOnly && (
                    <div className={styles.btnsContainer}>
                        <PrimaryButton
                            onClick={validateUpdateAccessPermissions}
                            disabled={!updateBtnIsActive}
                            className={styles.updateBtn}
                        >
                            <Text className={styles.text}>Save</Text>
                            {updatingInProgress && (
                                <Spinner className={styles.spinner} size={SpinnerSize.small} />
                            )}
                        </PrimaryButton>
                        <DefaultButton
                            disabled={!cancelBtnIsActive}
                            onClick={onCancelClick}
                        >
                            Cancel
                        </DefaultButton>
                    </div>
                )}
                {!!error && 
                    <div>
                        <MessageBar
                            isMultiline={true}
                            messageBarType={MessageBarType.error}
                        >
                            {error.message}
                        </MessageBar>
                    </div>
                }
            </div>}
        </>
    );
}
