import { IRefiner } from "../../../model/Refiner";
import { ManagedPropertyViewType } from "../../../model/ManagedPropertyViewType";

export interface ICurrentClientContext {
    currentUser: IUser | null;
    headerBannerText: string | null;
    favicon?: string;
    primaryIcon?: string;
    navbarIcon?: string;
    applicationName?: string;
    showValidationErrors?: boolean;    
    reloadInterval?: number;
    maxReloadAttemptTime?: number;
}

export interface IUser extends ISimpleUser {
    groupMemberships?: string[] | null;
    orgLevel2?: string | null;
    orgLevel3?: string | null;
    orgLevel4?: string | null;
    isDLO?: boolean | null;
    isDepSec?: boolean | null; // Deputy Secretary
    isSecretary?: boolean | null;
    isAdmin?: boolean | null;
    myProxies?: ISimpleUser[] | null;
    proxyFor?: ISimpleUser[] | null;
    proxyExpiry?: string | null;
}

export interface IUsers {
    users?: IUser[];
}

export interface ISimpleUser {
    siteUserId?: number | null;
    graphUserId?: string | null;
    displayName?: string | null;
    jobTitle?: string | null;
    userPrincipalName?: string | null;
    mail?: string | null;
}

export interface ISimpleUsers {
    users?: IUser[];
}

export interface IUserFieldActivity{
    userDisplayName: string;
    addedDateTime: string;
}

export enum HeaderLinkType {
    Internal = 0,
    External = 1
}
export interface IHeaderLink {
    tooltip: string;
    iconName: string;
    href: string;
    order: number;
    type: HeaderLinkType;
    isMobile: boolean;
    isAdminOnly: boolean;
}

export interface ITerm {
    id?: string | null;
    name?: string | null;
    terms?: ITerm[] | null;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    localCustomProperties?: any;
}

export interface ITaskMapping {
    task?: ITerm;
    assignedTo?: ISimpleUser;
    dueDate?: Date;
    createdDate?: Date;
    assignedToRole?: IWorkflowRole;
    proxyAssignedTask?: boolean;
}

export interface ITermSet {
    id?: string | null;
    name?: string | null;
    terms?: ITerm[] | null;
}

export interface ITermGroup {
    termSets?: ITermSet[] | null;
}

export interface IOrgLevel4 {
    name?: string | null;
}

export interface IOrgLevel3 {
    name?: string | null;
    orgLevel4?: IOrgLevel4[] | null;
}

export interface IOrgLevel2 {
    name?: string | null;
    orgLevel3?: IOrgLevel3[] | null;
}

export interface IOrgLevel1 {
    name?: string | null;
    orgLevel2?: IOrgLevel2[] | null;
}

export interface IOrgs {
    orgLevel1?: IOrgLevel1[] | null;
}

export type IOrgLevel = IOrgLevel1 | IOrgLevel2 | IOrgLevel3 | IOrgLevel4;

export interface IRecordColumn {
    key: string;
    name: string;
    sortColumnName?: string;
    refinerColumnName?: string;
    isSortable: boolean;
    type?: ManagedPropertyViewType;
    isCustom?: boolean;
}

export interface IRecordKeyRoles extends IRecordDynamicFields {
    initiator?: (IUser | ISimpleUser)[];
}

export interface IRecordDynamicFields {
    fieldValues?: IFieldValue[];
}

export interface IRecordInformation extends IRecordDynamicFields {
    decisionCategory?: ITerm[];
    organisationLv1?: string;
    organisationLv2?: string;
    organisationLv3?: string;
    organisationLv4?: string;
    overrideDefaultAccess?: boolean;
    restrictAccessTeams?: ITerm[];
    securityClassification?: ITerm[];
    viewAccessUsers?: (IUser | ISimpleUser)[];
    signatureRequired?: boolean;
    relatedRecords?: string;
    recordFlags?: ITerm[];

    cabinetWorkingFolderNumber?: string;

    from?: IContact;
    to?: IContact[];
    dateWritten?: string;
    dateReceived?: string;

    comments?: string;
}
export interface ICreateRecordRequest extends IRecordKeyRoles, IRecordInformation {
    title?: string;
    recordType?: string; // Actually Record Subtype eg: BMin

    topicSummary?: string;
    requestFrom?: ITerm[];
    dateRequested?: string;
    timeframe?: ITerm[];
    dueDateRequiredBy?: string;
    signatureRequired?: boolean;
    recordFlags?: ITerm[];

    selectedTemplates?: ITemplate[];
    documentUploads?: IUploadRecordDocumentRequest[];
    documents?: IBaseRecordDocument[];
    fileOrder?: IFileOrder;

    from?: IContact;
    to?: IContact[];
    originalCorrespondence?: IBaseFile;
    dateWritten?: string;
    dateReceived?: string;
}

export interface ICopyRecordRequest {
    recordId?: string;
    copyDocuments?: boolean;
}

export interface ISupersedeRecordRequest {
    recordId?: string;
    copyDocuments?: boolean;
}

export interface IRecordDocuments {
    deptWorkProducts?: IFile[];
    attachments?: IFile[];
    supportingDocuments?: IFile[];
    signedAndApprovedProducts?: IFile[];
    snapshots?: ISnapshot[];
}

export interface ITimelineSubentry {
    activityDescription?: string;
    completedDate?: string;
    users?: ISimpleUser[];
    onBehalfOf?: ISimpleUser[];
    role?: string;
}

export interface ITimelineEntry {
    stageName: string;
    subEntries: ITimelineSubentry[];
    currentTasks: ITaskMapping[];
    isBlocked: boolean;
    isActive: boolean;
    comments?: string;
    lastModifiedTime?: string;
}

export interface ISearchFieldValue {
    fieldName: string;
    value: string;
}

export interface IRecordResult {
    recordId?: IRecordResultValue<string>;
    title?: IRecordResultValue<string>;
    tasks?: IRecordResultValue<string>;
    timeframe?: IRecordResultValue<string>;
    stage?: IRecordResultValue<string>;
    dueDateDecisionMaker?: IRecordResultValue<string>;
    dueDateCurrentTask?: IRecordResultValue<string>;
    recordType?: IRecordResultValue<IContentType>;

    dateRequested?: IRecordResultValue<string>;
    dueDateRecommender?: IRecordResultValue<string>;
    dueDateRequiredBy?: IRecordResultValue<string>;
    created?: IRecordResultValue<string>;
    modified?: IRecordResultValue<string>;
    securityClassification?: IRecordResultValue<string>;
    decisionCategory?: IRecordResultValue<string>;
    eventNeeds?: IRecordResultValue<string>;
    mediaCoverage?: IRecordResultValue<string>;
    requestFrom?: IRecordResultValue<string>;

    decisionMaker?: IRecordResultValue<string>;
    assignedTo?: IRecordResultValue<string>;
    organisationLv1?: IRecordResultValue<string>;
    organisationLv2?: IRecordResultValue<string>;
    organisationLv3?: IRecordResultValue<string>;
    organisationLv4?: IRecordResultValue<string>;

    onhold?: IRecordResultValue<boolean>;
    noFurtherAction?: IRecordResultValue<boolean>;
    withdrawn?: IRecordResultValue<boolean>;
    cancelled?: IRecordResultValue<boolean>;
    superseded?: IRecordResultValue<boolean>;

    values?: IRecordResultValue<ISearchFieldValue[]>;
}

export interface IRecordResultValue<T> {
    value?: T;
}

export interface IRecordResultColumn {
    displayName?: string;
    managedPropertyName?: string;
    key?: string;
    isSortable?: boolean;
    isSelected?: boolean;
    filters?: string[];
    type: ManagedPropertyViewType;
    isCustom?: boolean;
}

export interface IRecordResults {
    records?: IRecordResult[];
    columns?: IRecordResultColumn[];
    refiners?: IRefiner[];
}

export interface IRecommendation {
    id: string;
    type: string;
    description: string;
    state?: string;
}

export interface IRecord extends ICreateRecordRequest {
    name?: string;

    createdBy?: (IUser | ISimpleUser)[];
    modifiedBy?: (IUser | ISimpleUser)[];
    recordId?: string;
    created?: string;
    modified?: string;
    stage?: string;
    onHold?: boolean;
    noFurtherAction?: boolean;
    withdrawn?: boolean;
    cancelled?: boolean;
    superseded?: boolean;
    actionedByMcu?: boolean;

    permissionSets?: string;
    canCurrentUserEditRecord?: boolean;
    canCurrentUserEditRecordLimitedAccess?: boolean;
    canCurrentUsedEditRecordAdminLimitedAccess?: boolean;
    canCurrentUserEditRecordDocuments?: boolean;
    canCurrentUserUploadSignedDocuments?: boolean;
    canCurrentUserUploadSupportingDocuments?: boolean; //2844 Changes
    canCurrentUserUploadOnlySupportingDocuments?: boolean;
    canCurrentUserViewDocumentsFromOtherUsers?: boolean;
    canCurrentUserRemoveDocuments?: boolean;
    canCurrentUserAddDocuments?: boolean;

    isCurrentUserRecordAdmin?: boolean;

    dueDateCurrentTask?: string;
    taskMappings?: ITaskMapping[];
    assignedTo?: (IUser | ISimpleUser)[];

    analysis?: string;
    recommendations?: IRecommendations;

    supersededFrom?: string;
    supersededBy?: string;

    initiatedBy?: IRecordDepartmentActivityItem;
    approvedForCabinetMeetingBy?: IRecordDepartmentActivityItem;
    branchEndorsedBy?: IRecordDepartmentActivityItem;
    recommendedBy?: IRecordDepartmentActivityItem[];
    departmentApprovedBy?: IRecordDepartmentActivityItem;

    decisionMadeBy?: IRecordDepartmentActivityItem;
    signaturesCollectedBy?: IRecordDepartmentActivityItem;
    closedBy?: IRecordDepartmentActivityItem;
    markedAsBy?: IRecordDepartmentActivityItem;
    customActivities?: IRecordDepartmentActivityItem[];

    timelineEntries?: ITimelineEntry[];
    iWantToOptionCollection?: IIWantToOptions;
}

export interface IUpdateRecordRecommendations extends Partial<IRecordInformation> {
    recordId: string;
    title?: string;
    topicSummary?: string;
    analysis?: string;
    recommendations?: IRecommendations;
}

export interface IRecommendations {
    recommendations?: IRecommendation[];
}

export interface IUpdateRecordRequest extends Partial<ICreateRecordRequest> {
    recordId: string;
}

export interface IUpdateKeyDates {
    fieldValues?: IFieldValue[];
}

export interface IUpdateRecordKeyDatesRequest extends Partial<IUpdateKeyDates> {
    recordId: string;
    comments: string;
}

export enum RecordDocumentType {
    departmentWorkProduct = 0,
    supportingDocument = 1,
    attachment = 2,
    signedAndApprovedDocument = 3
}

export interface IUploadRecordDocumentRequest {
    recordId: string;
    recordName: string;
    name: string;
    content?: string;
    serverRelativePath?: string;
    type: RecordDocumentType;
    copyFromSharepointPersonalFileId?: string;
}

export interface IUploadRecordSignedDocumentRequest extends IUploadRecordDocumentRequest {
    CopyFromSharepointPersonalFileId: string;
    documentToReplaceId: string;
}

export interface IUploadRecordTemplateRequest {
    recordId: string;
    recordName: string;
    name: string;
    url: string;
}

export interface IDeleteRecordDocumentRequest {
    recordId: string;
    recordName: string;
    name: string;
    type: RecordDocumentType;
}

export interface IEditRecordDocumentRequest {
    recordId: string;
    recordName: string;
    name: string;
    updatedName: string;
    type: RecordDocumentType;
}

export interface IReorderRecordDocumentRequest {
    recordId: string;
    recordName: string;
    fileOrder: IFileOrder;
}

export interface IFileOrder {
    deptWorkProductsOrder: string[];
    attachmentsOrder: string[];
    supportingDocumentsOrder: string[];
}
export interface IUpdateRecordIWantToActionRequest {
    recordId: string;
    nextStage?: ITerm;
    tasks?: ITerm[];
    assignedTo?: ISimpleUser[];
    dueDateCurrentTask?: string;
}

export interface ITask {
    task?: ITerm;
    assignedTo: ISimpleUser;
    dueDate: string;
    role?: string;
}

export interface IBulkUpdateStateResponse {
    totalRecords: number;
    processedRecords: number;
    batchName?: string;
    author?: string;
    hitoryLibraryUrl: string;
    errors?: string[];
    warnings?: string[];
}
export interface IStartBulkUpdateRequest {
    sourceCsvPath: string;
    validateOnly: boolean;
}

export interface IUpdateTaskRequest {
    comments?: string;
    recordId: string;
    action?: string;
    transitionId: string;
    newTasks?: ITaskMapping[];
    recommendations?: IRecommendations;
}

export interface IWorkflowTask {
    id: string;
    name: string;
    transitionId: string;
    roleAssignee?: string;
}

export interface IWorkflowRole{
    displayName: string;
    internalName: string;
}

export interface IIWantToDialog {
    title?: string;
    description?: string;
    roles?: IWorkflowRole[];
    tasks?: IWorkflowTask[];
    action?: string;
    overrideTransitionId?: string;
    buttonLabel?: string;
    dueDateCurrentTask?: string;

    canSelectMultipleRoles?: boolean;
    canSelectDueDate?: boolean;
    isCommentsRequired?: boolean;
    commentType?: string;
    maxDueDate?: string;
    minDueDate?: string;

    canRecordRecommendations?: boolean;
    hideTaskSelection?: boolean;
    hideRoleSelection?: boolean;
    disableForm?: boolean;
    hideCommentsInput?: boolean;
    hideButtons?: boolean;
}

export interface IIWantToOption {
    label?: string;
    tabKey?: string;
    customAction?: string;
    dialog?: IIWantToDialog;
    category?: string;
    lastModifiedRecordDateTime?: string;
}

export interface IIWantToOptions {
    options: IIWantToOption[];
}

export interface IUpdateRecordKeyRolesRequest extends Partial<IRecordKeyRoles> {
    recordId: string;
    comments: string;    
    rolesOnlyUpdate?: boolean;
}

export interface IUpdateDocumentSignaturesRequest {
    recordId: string;
    signatures: IFile[];
}

export interface IUpdateRecordInformationRequest extends Partial<IRecordInformation> {
    recordId: string;
    title?: string;
    topicSummary?: string;
}

export interface IDefaultKeyRolesRequest {
    recordType: string;
    decisionCategory: ITerm | null;
    organisationLv1?: string;
    organisationLv2?: string;
    organisationLv3?: string;
    organisationLv4?: string;
}

export interface IDefaultKeyRoles {
    decisionMaker?: ISimpleUser[];
}

export interface IGeneratePdfPackRequest {
    recordId: string;
    title?: string;
}

export interface IGenerateExportPdfPackRequest {
    recordIds: string;
}

export interface IGenerateExportCsvPackRequest extends IGetRecordsRequest {
    recordIds: string[];
}

export interface IGenerateOneNoteHtmlRequst {
    recordId: string;
    title?: string;
}

export interface IOptionButton {
    actionKey: string;
    title: string;
}

export interface ITemplate extends Partial<IFile> {
    name: string;
    url: string;
    fullUrl?: string;
}

export interface IBaseFile {
    name: string;
    path: string;
}

export interface IBaseRecordDocument extends IBaseFile {
    type: RecordDocumentType;
}

export interface IFile extends IBaseFile {
    id?: string;
    contentURL?: string;
    driveId?: string;
    itemId?: string;
    signatureRequired?: boolean;
    signatureInstructions?: string;
    documentReference?: string;
    sensitivityLabel?: string;
    createdBy?: string;
}

export interface ISnapshot {
    createdDate?: string;
    version?: string;
    sentBy?: ISimpleUser;
    sentByRole?: string;
    sentTo?: ISimpleUser;
    sentToRole?: string;
    comments?: string;
    name?: string;
    contentURL?: string;
}

export interface IPossibleFieldValue {
    key: string;
    value: string;
}

export enum FieldTypes {
    Invalid = 0,
    Text = 1,
    SelectManagedMetadata = 2,
    MultiSelectManagedMetadata = 3,
    Role = 4,
    DateOnly = 5,
    TimeOnly = 6,
    DateTime = 7,
    RoleMultiSelect = 8,
    MultilineText = 9,
    RadioManagedMetadata = 10,
    RadioChoice = 11
}

export interface IContentTypeField {
    displayName: string;
    description: string;
    internalName: string;
    fieldType: number;
    showInHeader: boolean;
    notEditable: boolean;
    isRequired: boolean;
    isActive: boolean;
    isNotRequiredOnCreation: boolean;
    stages: string;
    dataSource: string;
    /**
     * System category: core, custom
     */
    sysCategory: string;
    /** 
     * Field wizard position.
     * 0. - Record information page
     * 1. - Record response page
     */
    wizardPosition: number;
}

export interface IStage {
    Name: string;
    Order: number;
}

export interface IContentType {
    rowKey: string;
    category: string;
    prefix: string;
    color: string;
    isCorrespondenceType: boolean;
    mandatoryCorrespondenceUpload?: boolean;
    //Serialized array of IStage objects.
    stagesJson: string;
}

export interface IFieldValue {
    fieldInternalName: string;
    valueInJson: string;
}

export enum ContactSource {
    New = 0,
    AadUser = 1,
    Contact = 2,
    Organization = 3
}

export interface IContact {
    id: string;
    fullName: string;
    jobTitle?: string;
    email?: string;
    emailIsNotProvided: boolean;
    workPhone?: string;
    address?: string;
    organization?: IContact;
    sourceType: ContactSource;
}

export interface ICorrespondenceFile extends IBaseFile {
    from?: IContact;
    to?: IContact[];
    subject?: string;
    //should be strings if we want to put it to redux store
    sentOn?: string;
    receivedOn?: string;
}

export interface IBinaryFile extends IFile {
    name: string;
    content: string;
}

export enum Move {
    Up,
    Down
}

export enum FormMode {
    New,
    Edit,
    View
}

export interface IRecordDepartmentActivityItem {
    label: string;
    users: (IUser | ISimpleUser)[];
    date: string;
    comments?: string;
    onBehalfOf?: (IUser | ISimpleUser)[];
    stage?: string;
}

export interface IRecordDepartmentActivities {
    activities?: IRecordDepartmentActivityItem[];
}
export interface IUpdateActivityLogRequest {
    recordId: string;
    comment: string;
}
export interface IGetActivityLogCsvRequest {
    recordId: string;
}
export interface IRecordActivityListItem {
    label: string;
    text: string;
}

export interface IRecordActivity {
    users: IUser[];
    statement: string;
    listItems: IRecordActivityListItem[];
    icon: string;
    date: string;
}

export interface IRecordActivities {
    activities: IRecordActivity[];
}

export enum RecordViewType {
    Active = 0,
    My = 1,
    Open = 2,
    Closed = 3,
    MyAll = 4,
    All = 5,
    Search = 6
}

export interface IGetRecordsRequest {
    recordViewType: RecordViewType;
    getRefiners: boolean;
    getResults: boolean;

    searchTerm?: string;
    orderByManagedProperty?: string;
    orderByDescending?: boolean;
    filters?: string[];
    proxy?: string;
    managedProperties?: string[];
    saveConfiguration?: boolean;
}

export interface IPdfPackResult {
    filePath: string;
    fileName: string;
}

export enum NotificationsChannel {
    Email = 0,
    Teams = 1
}

export enum NotificationsFrequencyType {
    All = 0,
    ImportantOnly = 1,
    AllExceptExcluded = 2,
    Muted = 99
}

export enum NotificationsOption {
    RoleChanged = 1,
    SentToMeoAction = 2,
    SentBackOrToAnother = 3,
    StatusChanged = 4,
    CommentAdded = 5,
    KeyDateChanged = 6,
    DetailsChanged = 7
}

export interface INotificationsSettings {
    notificationsChannel: NotificationsChannel;
    notificationsFrequencyType: NotificationsFrequencyType;
    excludedNotifications?: NotificationsOption[];
    proxyNotificationsChannel: NotificationsChannel;
    proxyNotificationsFrequencyType: NotificationsFrequencyType;
    proxyExcludedNotifications?: NotificationsOption[];
}

export interface IUserSettings extends INotificationsSettings {
    settingsSubScopeId?: string;
    isOverridenOnSubScope?: boolean;
    notifyUsersOfDecision?: ISimpleUser[];
    notifyUsersOfRecommendation?: ISimpleUser[];
    hasAccessToAIFeatures: boolean;
}

export interface IProxyEmailTemplates {
    requestDeleteProxy?: string;
    requestEditProxyEndDate?: string;
    requestNewProxy?: string;
}

export interface IKeyValuePair {
    key: string;
    value: string;
}

export interface IUserValidation {
    message?: string | null;
    ok: boolean;
}

export interface IAppSettings {
    favicon?: string;
    primaryIcon?: string;
    navbarIcon?: string;
}

export interface IDocumentSearchResponse {
    score: number;
    highlights: string[];
    fileName: string;
    fileSharepointWebUri: string;
}

export interface IOpenAIDocumentGenerationRequest {
    queryQuestion: IMinisterialQuestion;
    useInternalSearch: boolean;
    extractPdfTextContent: boolean;
    embedFileContent: boolean;
    documentType: string;
    useAssistantApi: boolean;
    rawOutput: boolean;
    files: IDocumentGenerationFile[];
    responseDocumentTemplateFileContentBase64: string;
    responseDocumentTemplateFileName: string;
    responseDocumentTemplateSharepointUrl: string;
}

export interface IDocumentGenerationFile {
    fileName: string;
    fileSharepointWebUri: string;
}

export interface IMinisterialQuestion {
    question: string;
}

export interface IWorkflowState {
    partitionKey: string;
    rowKey: string;
    name: string;
    canManageSignatures?: boolean;
}

export interface IWorkflowTransition {
    partitionKey: string;
    rowKey: string;
    sourceStateId: string;
    targetStateId: string;
    targetStage: string;
    targetRoleAssignment: string;
    actionName: string;
    activityDescription: string;
    canRecordRecommendations?: boolean;
    canSelectMultipleRoles?: boolean;
    dialogTitle: string;
    dialogDescription: string;
    buttonLabel: string;
    canSelectDueDate?: boolean;
    mapTasksToProxies?: boolean;
    commentInputLabel: string;
    isCommentsRequired?: boolean;
    hideTaskSelection?: boolean;
    hideRoleSelection?: boolean;
    disableForm?: boolean;
    hideCommentsInput?: boolean;
    hideButtons?: boolean;    
    shouldGenerateSnapshot?: boolean;
}

export interface IWorkflowTransitionGroup{
    partitionKey: string;
    rowKey: string;
    groupName: string;
    startingStateId: string;
    targetRecordTypes: string;
    transitionItemIds: string;
    startingStage: string;
    startingRoleAssignment: string;
}

export interface IWorkflowRecordType {
    name: string;
    roles: IWorkflowRole[];
    stages: string[];
}

export interface IWorkflowConfig {
    states: IWorkflowState[];
    transitions: IWorkflowTransition[];
    transitionGroups: IWorkflowTransitionGroup[];
    recordTypes: IWorkflowRecordType[];
}

export interface IOpenAIDocumentGenerationResponse {
    answer: string;
    messages: string[];
    generatedFileSharepointPath: string;
    generatedFileSharepointLinkingUrl: string;
    generatedFileSharepointWOPIFrameUrl: string;
    generatedFileSharepointDocumentId: string;
    generatedFileSharepointFileName: string;
    referencedFiles: IDocumentGenerationFile[];
}

export default interface IApiServiceExecutor {
    GetAccessRestrictedUsersHistory(recordId: string): Promise<IUserFieldActivity[] | null>;
    GetRecordActivityLog(recordId: string): Promise<IRecordActivities | null>;
    GetRecordActivityCsv(recordId: string): Promise<IBaseFile | null>;
    CreateRecord(createRecordRequest: ICreateRecordRequest): Promise<IRecord | null>;
    CopyRecord(copyRecordRequest: ICopyRecordRequest): Promise<IRecord | null>;
    ClearCache(): Promise<boolean>;
    SupersedeRecord(supersedeRecordRequest: ISupersedeRecordRequest): Promise<IRecord | null>;
    UpdateRecord(updateRecordRequest: IUpdateRecordRequest): Promise<IRecord | null>;
    UpdateTask(updateRecordRequest: IUpdateTaskRequest): Promise<IRecord | null>;
    UpdateActivityLog(updateActivityLogRequest: IUpdateActivityLogRequest): Promise<boolean | null>;
    UpdateCreateContact(contact: IContact): Promise<IContact | null>;
    UploadRecordDocument(
        uploadRecordDocumentRequest: IUploadRecordDocumentRequest
    ): Promise<string | null>;
    UploadRecordSignedDocument(
        uploadRecordDocumentRequest: IUploadRecordSignedDocumentRequest
    ): Promise<IRecordDocuments | null>;
    UploadRecordTemplate(
        uploadRecordTemplateRequest: IUploadRecordTemplateRequest
    ): Promise<boolean>;
    UploadFileToPersonalStorage(request: IFile): Promise<IBaseFile | null>;
    UploadAndParseCorrespondenceFile(request: IFile): Promise<ICorrespondenceFile | null>;
    ValidateUserAccess(): Promise<IUserValidation | null>;
    GetCurrentContext(): Promise<ICurrentClientContext | null>;
    GetCurrentUser(): Promise<IUser | null>;
    GetUsers(searchTerm: string): Promise<ISimpleUsers | null>;
    GetAllTermSets(): Promise<ITermGroup | null>;
    GetOrgHierarchy(): Promise<IOrgs | null>;
    GetRecord(recordId: string, includeTimelineAndMenu: boolean): Promise<IRecord | null>;
    GetTimelineEntries(recordId: string): Promise<ITimelineEntry[] | null>;
    GetRecords(request: IGetRecordsRequest): Promise<IRecordResults | null>;
    GetDefaultKeyRoles(
        defaultKeyRolesRequest: IDefaultKeyRolesRequest
    ): Promise<IDefaultKeyRoles | null>;
    GetWorkflowDiagram(recordTypeName: string): Promise<string | null>;
    GetWorkflowConfig(): Promise<string | null>;
    SetWorkflowConfig(workflowConfig: IWorkflowConfig): Promise<boolean>;
    GetContentTypeFields(contentTypeName: string): Promise<IContentTypeField[] | null>;
    GetContentTypesFieldsSchema(): Promise<string>;
    GetContentTypes(): Promise<IContentType[] | null>;
    GetContentTypesSchema(): Promise<string>;
    GetContacts(query?: string, types?: ContactSource[], top?: number): Promise<IContact[]>;
    GetAvailableTemplates(recordType: string): Promise<ITemplate[] | null>;
    GetAvailableRecordFlags(recordType: string): Promise<ITerm[] | null>;
    GetIWantToOptions(recordId: string): Promise<IIWantToOptions | null>;
    GetRecordDocuments(recordId: string, recordName: string): Promise<IRecordDocuments | null>;
    UpdateDocumentSignatures(
        UpdateDocumentSignatures: IUpdateDocumentSignaturesRequest
    ): Promise<boolean>;
    GenerateWatermarkedPDF(filePath: string): Promise<string | null>;
    GetRecordDepartmentActivity(recordId: string): Promise<IRecordDepartmentActivities | null>;
    DeleteRecordDocument(
        deleteRecordDocumentRequest: IDeleteRecordDocumentRequest
    ): Promise<boolean>;
    DeleteFileFromPersonalStorage(deleteRequest: IBaseFile): Promise<boolean>;
    EditRecordDocument(editRecordDocumentRequest: IEditRecordDocumentRequest): Promise<boolean>;
    ReorderRecordDocument(
        reorderRecordDocumentRequest: IReorderRecordDocumentRequest
    ): Promise<boolean>;
    GeneratePdfPack(pdfPackRequest: IGeneratePdfPackRequest): Promise<IPdfPackResult | null>;
    GenerateExportPdfPack(pdfPackRequest: IGenerateExportPdfPackRequest): Promise<string | null>;
    GenerateExportCsvPack(pdfPackRequest: IGenerateExportCsvPackRequest): Promise<IBaseFile | null>;
    GenerateOneNoteHtml(
        generateOneNoteHtmlRequst: IGenerateOneNoteHtmlRequst
    ): Promise<string | null>;
    GetApiBaseUrl(): Promise<string>;
    UpdateUserSettings(settings: IUserSettings): Promise<boolean>;
    GetUserSettings(subScopeId?: string): Promise<IUserSettings | null>;
    GetProxyEmailTemplates(): Promise<IProxyEmailTemplates | null>;
    GetHeaderLinks(): Promise<IHeaderLink[] | null>;
    GetUserPhoto(userPrincipal: string): Promise<string | null>;
    GetPublicHolidays(): Promise<Date[] | null>;
    GetBulkUpdateState(): Promise<IBulkUpdateStateResponse | null>;
    StartBulkUpdate(model: IStartBulkUpdateRequest): Promise<IBulkUpdateStateResponse | null>;
    GetAppSettings(): Promise<IAppSettings | null>;
    UpdateAppSettings(appSettings: IAppSettings): Promise<boolean | string>;
    UpdateAppSettings(appSettings: IAppSettings): Promise<boolean | string>;
    SearchDocuments(search: string): Promise<IDocumentSearchResponse[] | null>;
    GenerateDocumentWithAI(
        request: IOpenAIDocumentGenerationRequest
    ): Promise<IOpenAIDocumentGenerationResponse | null>;
}
