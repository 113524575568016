import React from "react";
import { Dispatch } from "@reduxjs/toolkit";
import { useDispatch, useSelector } from "react-redux";
import { AccountInfo } from "@azure/msal-browser";
import { Spinner, PrimaryButton } from "@fluentui/react";
import {
    LogInState,
    selectAccount,
    selectErrorMessage,
    selectLogInState,
    signInAsync
} from "./signInSlice";
import styles from "./SignIn.module.scss";

export interface ISignInProps {
    supressAutoLoad?: boolean;
    children?: JSX.Element | JSX.Element[];
}

export function SignIn(props: ISignInProps): JSX.Element {
    const logInState: LogInState = useSelector(selectLogInState);
    const account: AccountInfo | null = useSelector(selectAccount);
    const errorMessage: string | null | undefined = useSelector(selectErrorMessage);

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const dispatch: Dispatch<any> = useDispatch();

    React.useEffect(() => {
        if (!props.supressAutoLoad && logInState === LogInState.NotLoggedIn) {
            dispatch(signInAsync());
        }
    }, [logInState, dispatch, props.supressAutoLoad]);

    const renderContent = () => {
        switch (logInState) {
            case LogInState.NotLoggedIn:
                return (
                    <div className={styles.centerContainer}>
                        <div className={styles.signIn}>
                            <Spinner label="Signing you in..." size={3} />
                        </div>
                    </div>
                );
            case LogInState.ValidatingLogin:
                return (
                    <div className={styles.centerContainer}>
                        <div className={styles.signIn}>
                            <Spinner label="Validating your access..." size={3} />
                        </div>
                    </div>
                );
            case LogInState.InvalidLogIn:
                return (
                    <div className={styles.centerContainer}>
                        <div className={styles.signInError}>
                            {account?.username ? (
                                <p>
                                    <strong>Signed in as:</strong> {account.username}
                                </p>
                            ) : (
                                <p>
                                    <strong>Error:</strong> Failed to sign in successfully.
                                </p>
                            )}
                            <p>{errorMessage || "There was an issue validating your access."}</p>
                            <PrimaryButton
                                text="Retry Sign-In"
                                onClick={() => dispatch(signInAsync())}
                                className={styles.retryButton}
                            />
                        </div>
                    </div>
                );
            case LogInState.ValidLogIn:
                return <>{props.children}</>;
            default:
                return null;
        }
    };

    return <>{renderContent()}</>;
}
