import { useDispatch, useSelector } from "react-redux";
import React, { useMemo } from "react";
import { Dispatch } from "@reduxjs/toolkit";
import {
    IContentTypeField,
    IOrgLevel1,
    IOrgLevel2,
    IOrgLevel3,
    IOrgLevel4,
    IOrgs,
    IRecord,
    ITerm,
    ITermSet
} from "../../services/Api/executor/IApiServiceExecutor";
import {
    editRecordDetailsHeader,
    fieldDescriptionCabinetWorkingFileNumber,
    fieldLabelOnEditDecisionRequiredDate,
    fieldLabelParliamentaryTitle,
    loadingMessage,
    fieldDescriptionFlags,
    fieldLabelFlags,
} from "../../providers/Constants/RecordWizardConstants";
import Select, { ISelectOptionProps } from "../../components/FormControls/Select/Select";
import { FieldWrapper } from "../../components/FormControls/FieldWrapper/FieldWrapper";
import { selectAllFields, selectCurrentRecord, selectInactiveFields, updateRecordInformationAsync } from "./manageRecordWrapperSlice";
import { useParams } from "react-router-dom";
import TextBox from "../../components/FormControls/TextBox/TextBox";
import { DefaultButton, MessageBar, MessageBarType, PrimaryButton, Separator, Spinner, SpinnerSize, Text } from "@fluentui/react";
import DatePicker from "../../components/FormControls/DatePicker/DatePicker";
import {
    getISelectOptionPropsFromIOrgLevel,
    getISelectOptionPropsFromIterm
} from "../wizardRecordInformation/WizardRecordInformation";
import {
    cicSelected,
    getManageDetailsDataAsync,
    resetManageRecordDetails,
    selectCabinetWorkingFileNumber,
    selectDateRequiredBy,
    selectDecisionCategories,
    selectDecisionCategoryTermSet,
    selectEnteredTitle,
    selectOrgHierarchy,
    selectOrgLevel1s,
    selectOrgLevel2s,
    selectOrgLevel3s,
    selectOrgLevel4s,
    selectSelectedDecisionCategory,
    selectSelectedOrgLevel1,
    selectSelectedOrgLevel2,
    selectSelectedOrgLevel3,
    selectSelectedOrgLevel4,
    selectSelectedSecurityClassificationsType,
    setCabinetWorkingFileNumber,
    setDateRequiredBy,
    setManageRecordDetails,
    setSelectedDecisionCategory,
    setSelectedOrgLevel1,
    setSelectedOrgLevel2,
    setSelectedOrgLevel3,
    setSelectedOrgLevel4,
    setTitle,
    selectRecordFlags,
    setSelectedRecordFlags,
    selectSelectedRecordFlags,
} from "./manageRecordDetails/manageRecordDetailsSlice";
import styles from "./ManageRecordDetails.module.scss";
import StringHelper from "../../helpers/StringHelper";
import DateHelper from "../../helpers/DateHelper";
import { getFieldDescription, getFieldDisplayName, isFieldInActive } from "../../helpers/ModelHelper";

export function ManageParliamRecordDetails(): JSX.Element {
    const currentRecord: IRecord | null = useSelector(selectCurrentRecord);

    const enteredTitle: string | undefined = useSelector(selectEnteredTitle);
    const selectedDateRequiredBy: Date | undefined = useSelector(selectDateRequiredBy);

    const decisionCategoryTermSet: ITermSet | null = useSelector(selectDecisionCategoryTermSet);
    const orgHierarchy: IOrgs | null = useSelector(selectOrgHierarchy);

    const decisionCategories: ITerm[] = useSelector(selectDecisionCategories);
    const orgLevel1s: IOrgLevel1[] = useSelector(selectOrgLevel1s);
    const orgLevel2s: IOrgLevel2[] = useSelector(selectOrgLevel2s);
    const orgLevel3s: IOrgLevel3[] = useSelector(selectOrgLevel3s);
    const orgLevel4s: IOrgLevel4[] = useSelector(selectOrgLevel4s);

    const selectedDecisionCategory: ITerm | null = useSelector(selectSelectedDecisionCategory);
    const selectedOrgLevel1: IOrgLevel1 | null = useSelector(selectSelectedOrgLevel1);
    const selectedOrgLevel2: IOrgLevel2 | null = useSelector(selectSelectedOrgLevel2);
    const selectedOrgLevel3: IOrgLevel3 | null = useSelector(selectSelectedOrgLevel3);
    const selectedOrgLevel4: IOrgLevel4 | null = useSelector(selectSelectedOrgLevel4);

    const allFields: IContentTypeField[] | null = useSelector(selectAllFields);
    const inactiveFields: IContentTypeField[] | null = useSelector(selectInactiveFields);

    const recordFlags: ITerm[] = useSelector(selectRecordFlags);
    const selectedRecordFlags: ITerm[] | null = useSelector(selectSelectedRecordFlags);
    const isCICSelected: boolean = useSelector(cicSelected);
    const selectedSecurityClassificationsType: ITerm | null = useSelector(
        selectSelectedSecurityClassificationsType
    );
    const cabinetWorkingFileNumber: string | undefined = useSelector(
        selectCabinetWorkingFileNumber
    );

    const [updatingInProgress, setUpdatingInProgress] = React.useState(false);
    const [error, setError] = React.useState<Error | undefined>();
    const [initilized, setInitilized] = React.useState<boolean>(false);
    // 4. GET DISPATCH
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const dispatch: Dispatch<any> = useDispatch();    
    // 5. DEFINE COMPONENT HOOKS
    const { recordId } = useParams<{ recordId: string }>();

    const readOnly: boolean = React.useMemo(
        () =>
            !currentRecord?.isCurrentUserRecordAdmin && (
                !currentRecord?.canCurrentUserEditRecord ||
                !!currentRecord?.canCurrentUserEditRecordLimitedAccess ||
                !!currentRecord?.noFurtherAction ||
                !!currentRecord?.withdrawn
            ),
        [
            currentRecord?.canCurrentUserEditRecord,
            currentRecord?.canCurrentUserEditRecordLimitedAccess,
            currentRecord?.noFurtherAction,
            currentRecord?.withdrawn,
            currentRecord?.isCurrentUserRecordAdmin
        ]
    );

    React.useEffect(() => {
        dispatch(getManageDetailsDataAsync());
        return () => {
            dispatch(resetManageRecordDetails());
        };
    }, [dispatch]);

    React.useEffect(() => {
        if (currentRecord && decisionCategoryTermSet && orgHierarchy) {
            dispatch(setManageRecordDetails(currentRecord));
        }
    }, [dispatch, currentRecord, decisionCategoryTermSet, orgHierarchy]);


    //currentRecord.parliamentaryRaisedByMember?.name != selectedRaisedByMember?.name ||
    const anyChangesApplied: boolean = React.useMemo(() => { 
        return !!currentRecord && (
            !StringHelper.isEqual(currentRecord.title, enteredTitle) 
            || !DateHelper.isEqual(currentRecord.dueDateRequiredBy, selectedDateRequiredBy)            
            || !StringHelper.isEqual((currentRecord.decisionCategory || [])[0]?.id, selectedDecisionCategory?.id)
            || !StringHelper.isEqual(currentRecord.organisationLv1, selectedOrgLevel1?.name)
            || !StringHelper.isEqual(currentRecord.organisationLv2, selectedOrgLevel2?.name) 
            || !StringHelper.isEqual(currentRecord.organisationLv3, selectedOrgLevel3?.name) 
            || !StringHelper.isEqual(currentRecord.organisationLv4, selectedOrgLevel4?.name)
            || !StringHelper.isEqual((currentRecord.securityClassification || [])[0]?.name, selectedSecurityClassificationsType?.name)
            || !StringHelper.isEqual(currentRecord.cabinetWorkingFolderNumber, cabinetWorkingFileNumber) ||
            !(currentRecord?.recordFlags?.length === selectedRecordFlags?.length && currentRecord?.recordFlags?.every(x => selectedRecordFlags?.findIndex(s => s.id === x.id) !== -1)));
    },[
        currentRecord, 
        enteredTitle, 
        selectedDateRequiredBy,        
        selectedDecisionCategory,
        selectedOrgLevel1,
        selectedOrgLevel2,
        selectedOrgLevel3,
        selectedOrgLevel4,
        cabinetWorkingFileNumber,
        selectedSecurityClassificationsType,
        selectedRecordFlags,
    ]);

    const requiredFieldsIsNotEmpty: boolean = React.useMemo(() => {
        let result: boolean =
            !!enteredTitle &&
            !!selectedDateRequiredBy &&
            !!selectedOrgLevel1 &&
            !!selectedOrgLevel2;

        if (decisionCategories.length) {
            result = result && !!selectedDecisionCategory;
        }

        if(result && isCICSelected){
            result = !!cabinetWorkingFileNumber;
        }

        return result;
    }, [
        enteredTitle,
        selectedOrgLevel1,
        selectedOrgLevel2,
        selectedDateRequiredBy,
        selectedDecisionCategory,
        decisionCategories,
        isCICSelected,
        cabinetWorkingFileNumber
    ]);

    const formIsBlocked: boolean = React.useMemo(() => {
        return readOnly || updatingInProgress;
    },[readOnly, updatingInProgress]);

    const updateBtnIsActive: boolean = React.useMemo(() => {
        return anyChangesApplied && !formIsBlocked && requiredFieldsIsNotEmpty;
    },[anyChangesApplied, formIsBlocked, requiredFieldsIsNotEmpty]);

    const cancelBtnIsActive: boolean = React.useMemo(() => {
        return anyChangesApplied && !formIsBlocked;
    },[anyChangesApplied, formIsBlocked]);

    React.useEffect(() => {
        if (!initilized && currentRecord && enteredTitle) {
            setInitilized(true);
        }
    }, [initilized, currentRecord, enteredTitle]);

    const validateUpdate: () => void = React.useCallback((): void => {
        if (recordId && updateBtnIsActive) {
            setUpdatingInProgress(true);
            dispatch(
                updateRecordInformationAsync(
                    {
                        recordId: recordId,
                        title: enteredTitle,
                        decisionCategory: selectedDecisionCategory
                            ? [selectedDecisionCategory]
                            : undefined,
                        organisationLv1: selectedOrgLevel1?.name || "",
                        organisationLv2: selectedOrgLevel2?.name || "",
                        organisationLv3: selectedOrgLevel3?.name || "",
                        organisationLv4: selectedOrgLevel4?.name || "",
                        securityClassification: selectedSecurityClassificationsType ? [selectedSecurityClassificationsType] : undefined,
                        cabinetWorkingFolderNumber: cabinetWorkingFileNumber,
                        recordFlags: selectedRecordFlags ? selectedRecordFlags : undefined,
                    },
                    {
                        onSuccess: () => {
                            setUpdatingInProgress(false);
                        },
                        onError: e => {
                            setError(e);
                            setUpdatingInProgress(false);
                        }
                    }
                )
            );
        }
    }, [
        recordId,
        updateBtnIsActive,
        dispatch,
        enteredTitle,
        selectedDecisionCategory,
        selectedOrgLevel1?.name,
        selectedOrgLevel2?.name,
        selectedOrgLevel3?.name,
        selectedOrgLevel4?.name,
        selectedSecurityClassificationsType,
        cabinetWorkingFileNumber,
        selectedRecordFlags
    ]);

    const onCancelClick: () => void = React.useCallback(() => {
        if (currentRecord && recordId) {
            dispatch(setManageRecordDetails(currentRecord));
        }
    }, [dispatch, currentRecord, recordId]);

    const decisionCategoriesOptions: ISelectOptionProps[] =
        decisionCategories?.map(getISelectOptionPropsFromIterm) || [];
    
    //add fake value if org level 1 renamed in AAD. levels 2-3 handled in slice
    const orgLevel1Options: ISelectOptionProps[] = useMemo(()=>{
        const options:ISelectOptionProps[] = orgLevel1s?.map(getISelectOptionPropsFromIOrgLevel) || [];
        if(orgLevel1s && orgLevel1s.length && selectedOrgLevel1?.name && orgLevel1s.every(x=>x.name !== selectedOrgLevel1?.name)){
            options.push(getISelectOptionPropsFromIOrgLevel(selectedOrgLevel1));
        }
        return options;
    }, [orgLevel1s, selectedOrgLevel1]);

    const orgLevel2Options: ISelectOptionProps[] =
        orgLevel2s?.map(getISelectOptionPropsFromIOrgLevel) || [];
    const orgLevel3Options: ISelectOptionProps[] =
        orgLevel3s?.map(getISelectOptionPropsFromIOrgLevel) || [];
    const orgLevel4Options: ISelectOptionProps[] =
        orgLevel4s?.map(getISelectOptionPropsFromIOrgLevel) || [];
    const recordFlagsOptions: ISelectOptionProps[] = recordFlags
        .map(r => ({ key: r.id || "", text: r.name || ""})) || [];

    const currDate: Date = React.useMemo(() => new Date(), []);

    const titleDisabled: boolean = formIsBlocked;
    const onEditDecisionRequiredDateDisabled: boolean = formIsBlocked;
    const decisionCategoryDisabled: boolean = formIsBlocked || !decisionCategoriesOptions.length;
    const orgLevel1Disabled: boolean = formIsBlocked || !decisionCategoriesOptions.length;
    const orgLevel2Disabled: boolean = formIsBlocked || !orgLevel2Options.length;
    const orgLevel3Disabled: boolean = formIsBlocked || !orgLevel3Options.length;
    const orgLevel4Disabled: boolean = formIsBlocked || !orgLevel4Options.length;  
    const cabinetWorkingFileNumberDisabled: boolean = formIsBlocked;

    return (
        <div>
            <div className={styles.expandedHeader}>
                <h2 className={styles.expandedHeaderText}>{editRecordDetailsHeader}</h2>
            </div>
            {!initilized ? (
                <div className={styles.spinnerContainer}>
                    <Spinner
                        className={styles.spinner}
                        size={SpinnerSize.small}
                        label={loadingMessage}
                    />
                </div>
            ) : (
                <div className={styles.manageRecordDetailsContainer}>
                    <div className={styles.formContainer}>
                        <FieldWrapper>
                            <TextBox
                                name={fieldLabelParliamentaryTitle}
                                required={true}
                                rows={5}
                                disabled={titleDisabled}
                                value={enteredTitle}
                                onChange={(ev, newValue) => {
                                    dispatch(setTitle(newValue));
                                }}
                            />
                        </FieldWrapper>
                        <FieldWrapper>
                            <DatePicker
                                dateLabel={fieldLabelOnEditDecisionRequiredDate}
                                required={true}
                                includeTime={false}
                                disabled={onEditDecisionRequiredDateDisabled}
                                value={selectedDateRequiredBy}
                                onChange={date => dispatch(setDateRequiredBy(date))}
                                minDate={currDate}
                            />
                        </FieldWrapper>
                        {decisionCategoriesOptions.length > 0 && (
                            <FieldWrapper>
                                <Select
                                    name={getFieldDisplayName("ABCDecisionCategory", allFields)}
                                    options={decisionCategoriesOptions}
                                    required={decisionCategoriesOptions.length > 0}
                                    selectedKey={selectedDecisionCategory?.id || ""}
                                    disabled={decisionCategoryDisabled}
                                    onChange={option => {
                                        dispatch(setSelectedDecisionCategory(option?.key));
                                    }}
                                />
                            </FieldWrapper>
                        )}
                        <FieldWrapper description={getFieldDescription("ABCOrgLevel1", allFields)}>
                            <Select
                                name={getFieldDisplayName("ABCOrgLevel1", allFields)}
                                options={orgLevel1Options}
                                required={true}
                                disabled={orgLevel1Disabled}
                                selectedKey={selectedOrgLevel1?.name || ""}
                                showAsLoading={orgLevel1Options.length < 1}
                                onChange={option => {
                                    dispatch(setSelectedOrgLevel1(option?.key));
                                }}
                            />
                        </FieldWrapper>
                        <FieldWrapper description={getFieldDescription("ABCOrgLevel2", allFields)}>
                            <Select
                                name={getFieldDisplayName("ABCOrgLevel2", allFields)}
                                options={orgLevel2Options}
                                required={true}
                                disabled={orgLevel2Disabled}
                                selectedKey={selectedOrgLevel2?.name || ""}
                                onChange={option => {
                                    dispatch(setSelectedOrgLevel2(option?.key));
                                }}
                            />
                        </FieldWrapper>
                        {!isFieldInActive("ABCOrgLevel3", inactiveFields) &&       
                            <FieldWrapper description={getFieldDescription("ABCOrgLevel3", allFields)}>
                                <Select
                                    name={getFieldDisplayName("ABCOrgLevel3", allFields)}
                                    options={orgLevel3Options}
                                    required={false}
                                    disabled={orgLevel3Disabled}
                                    selectedKey={selectedOrgLevel3?.name || ""}
                                    onChange={option => {
                                        dispatch(setSelectedOrgLevel3(option?.key));
                                    }}
                                />
                            </FieldWrapper>
                        }
                        {!isFieldInActive("ABCOrgLevel4", inactiveFields) &&       
                            <FieldWrapper description={getFieldDescription("ABCOrgLevel4", allFields)}>
                                <Select
                                    name={getFieldDisplayName("ABCOrgLevel4", allFields)}
                                    options={orgLevel4Options}
                                    required={false}
                                    disabled={orgLevel4Disabled}
                                    selectedKey={selectedOrgLevel4?.name || ""}
                                    onChange={option => {
                                        dispatch(setSelectedOrgLevel4(option?.key));
                                    }}
                                />
                            </FieldWrapper>
                        }
                        {isCICSelected && (
                            <FieldWrapper description={fieldDescriptionCabinetWorkingFileNumber}>
                                <TextBox
                                    name={getFieldDisplayName("ABCCabinetWorkingFolderNumber", allFields)}
                                    required={isCICSelected}
                                    value={cabinetWorkingFileNumber}
                                    disabled={cabinetWorkingFileNumberDisabled}
                                    onChange={(ev, newValue) => {
                                        dispatch(setCabinetWorkingFileNumber(newValue));
                                    }}
                                />
                            </FieldWrapper>
                        )}
                        <FieldWrapper description={fieldDescriptionFlags}>
                            <Select
                                name={fieldLabelFlags}
                                options={recordFlagsOptions}
                                required={false}
                                defaultSelectedKey={selectedRecordFlags?.map(x => x?.id || "") || undefined}
                                disabled={recordFlagsOptions.length < 1 || formIsBlocked}
                                onChange={option => {
                                    dispatch(setSelectedRecordFlags(option?.key));
                                }}
                                isMultiSelect
                            />
                        </FieldWrapper>
                    </div>
                    {!readOnly && (
                        <div className={styles.footer}>
                            <Separator />
                            {!!error && (
                                <div>
                                    <MessageBar
                                        isMultiline={true}
                                        messageBarType={MessageBarType.error}
                                    >
                                        {error.message}
                                    </MessageBar>
                                </div>
                            )}
                            <div className={styles.btnsContainer}>
                                <PrimaryButton
                                    onClick={validateUpdate}
                                    disabled={!updateBtnIsActive}
                                    className={styles.updateBtn}
                                >
                                    <Text className={styles.text}>Save</Text>
                                    {updatingInProgress && (
                                        <Spinner className={styles.spinner} size={SpinnerSize.small} />
                                    )}
                                </PrimaryButton>
                                <DefaultButton
                                    disabled={!cancelBtnIsActive}
                                    onClick={onCancelClick}
                                >
                                    Cancel
                                </DefaultButton>
                            </div>
                        </div>
                    )}
                </div>
            )}
        </div>
    );
}
