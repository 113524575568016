import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import Backend from "i18next-http-backend";

interface TranslationObject {
    [key: string]: string | TranslationObject;
}

const deepMerge = (base: TranslationObject, override: TranslationObject): TranslationObject => {
    const merged: TranslationObject = { ...base };
    for (const key in override) {
        if (
            typeof override[key] === "object" &&
            !Array.isArray(override[key])
        ) {
            merged[key] = deepMerge(
                (merged[key] as TranslationObject) || {}, 
                override[key] as TranslationObject
            );
        } else {
            merged[key] = override[key];
        }
    }
    return merged;
};

const loadTranslations = async (): Promise<void> => {
    const overrides: Response = await fetch("/locales/translation-overrides.json");
    let overridesJson: TranslationObject = {};

    if (overrides.headers.get("content-type")?.indexOf("application/json") === 0) {
        overridesJson= await overrides.json();
    } 

    i18n.use(Backend)
        .use(initReactI18next)
        .init({
            fallbackLng: "en",
            debug: true,
            interpolation: {
                escapeValue: false,
            },
        });

    await i18n.loadNamespaces("translation");
    const existingTranslations: TranslationObject = 
        i18n.getResourceBundle("en", "translation") || {};
    const mergedTranslations: TranslationObject = deepMerge(existingTranslations, overridesJson);

    i18n.addResourceBundle("en", "translation", mergedTranslations, true, true);
};

loadTranslations();

export default i18n;
