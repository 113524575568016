import { Link, useHistory } from "react-router-dom";
//1. IMPORTS
import { useDispatch, useSelector } from "react-redux";
import React from "react";
import { DefaultButton, PrimaryButton } from "@fluentui/react";
import { Dispatch } from "@reduxjs/toolkit";
import {
    createRecordsAsync,
    nextPage,
    previousPage,
    resetWizardWrapper,
    selectCreatedRecords,
    selectErrorMessages,
    selectPreventNext,
    selectWizardStage,
    selectIsFirstStep,
    selectIsLastStep,
    setErrorMessages,
    WizardStates
} from "./recordWizardWrapperSlice";
import styles from "./RecordWizardWrapper.module.scss";
import { addSelectedFile, resetWizardCorrespondenceFiles } from "../wizardCorrespondenceFiles/wizardCorrespondenceFilesSlice";
import { resetWizardSummary, selectIsConfirmed } from "../wizardSummary/wizardSummarySlice";
import { WizardSummary } from "../wizardSummary/WizardSummary";
import {
    resetWizardResponseTemplateStep,
    uploadedFileOrder,
    uploadedDocs,
    userSelectedTemplates,
} from "../wizardResponseTemplate/wizardResponseTemplateStepSlice";
import {
    resetWizardResponseInformation,
    selectDateRequested,
    selectEnteredSummary,
    selectEnteredTitle,
    selectSelectedRequestFrom,
    selectSelectedTimeframe,
    selectSignatureRequired,
    selectSelectedRecordFlags,
} from "../wizardResponseInformation/wizardResponseInformationSlice";
import { WizardResponseInformation } from "../wizardResponseInformation/WizardResponseInformation";
import {
    resetWizardRecordInformation,
    selectCabinetWorkingFileNumber,
    selectCurrentFieldValues,
    selectActiveFields,
    selectSelectedAccessRestrictedUsers,
    selectSelectedDecisionCategory,
    selectSelectedOrgLevel1,
    selectSelectedOrgLevel2,
    selectSelectedOrgLevel3,
    selectSelectedOrgLevel4,
    selectSelectedOverrideDefaultAccess,
    selectSelectedRestrictAccessTeams,
    selectSelectedSecurityClassificationsType
} from "../wizardRecordInformation/wizardRecordInformationSlice";
import { ImsgParsedFile, stateSelectedFiles } from "../wizardCorrespondenceFiles/wizardCorrespondenceFilesSlice";
import { WizardCorrespondenceFiles } from "../wizardCorrespondenceFiles/WizardCorrespondenceFiles";
import { BatchCorrespondenceRecords, WizardCorrespondenceDetails } from "../wizardCorrespondenceDetails/WizardCorrespondenceDetails";
import { WizardRecordInformation } from "../wizardRecordInformation/WizardRecordInformation";
import { WizardRecordConfirmation } from "../wizardRecordConfirmation/WizardRecordConfirmation";
import {
    FormMode,
    resetWizardKeyRoles,
    selectSelectedInitiator,
    setWizardKeyRolesFormMode,
    selectCustomUserValues
} from "../wizardKeyRoles/wizardKeyRolesSlice";
import { WizardKeyRolesForm } from "../wizardKeyRoles/WizardKeyRolesForm";
import { WizardKeyRolesContainer } from "../wizardKeyRoles/WizardKeyRolesContainer";
import {
    resetWizardCreateRecord,
    selectSelectedRecordType
} from "../wizardCreateRecord/wizardCreateRecordSlice";
import { WizardCreateRecord } from "../wizardCreateRecord/WizardCreateRecord";
import {
    FieldTypes,
    IBaseRecordDocument,
    IContentType,
    IContentTypeField,
    ICreateRecordRequest,
    IFieldValue,
    IFileOrder,
    IOrgLevel1,
    IOrgLevel2,
    IOrgLevel3,
    IOrgLevel4,
    IRecord,
    ITemplate,
    ITerm,
    IUser
} from "../../services/Api/executor/IApiServiceExecutor";
import {
    breadcrumbCreateRecord,
    createRecordErrorMessage,
    currentStepHashKey,
    dashboardButton,
    wrapperBackButton,
    wrapperCreateRecordButton,
    wrapperExitButton,
    wrapperGetStartedButton,
    wrapperNextButton,
    wrapperOpenButton
} from "../../providers/Constants/RecordWizardConstants";
import Breadcrumbs from "../../components/FormControls/Breadcrumbs/Breadcrumbs";
import { AppRoutes } from "../../app/Constants";
import { WizardResponseTemplateStep } from "../wizardResponseTemplate/WizardResponseTemplateStep";
import { MessageBar, MessageBarType } from "@fluentui/react";
import { setAppTitle } from "../../app/globalSlices/contextSlice";
import { useTranslation } from "react-i18next";

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function resetAllRecordWizardState(dispatch: Dispatch<any>): void {
    dispatch(resetWizardCreateRecord());
    dispatch(resetWizardResponseInformation());
    dispatch(resetWizardRecordInformation());
    dispatch(resetWizardKeyRoles());
    dispatch(resetWizardSummary());
    dispatch(resetWizardWrapper());    
    dispatch(resetWizardResponseTemplateStep());
    dispatch(resetWizardCorrespondenceFiles());
}

let historyListener: { remove: () => void} | undefined;
let prevHostRelativeUrl: string | undefined;
export function RecordWizardWrapper(): JSX.Element {
    // eslint-disable-next-line @typescript-eslint/typedef
    const history = useHistory();
    // 2. SELECT CURRENT GLOBAL STATE
    // const count: number = useSelector(selectCount);
    const currentWizardPage: number = useSelector(selectWizardStage);

    const isFirstStep: boolean = useSelector(selectIsFirstStep);
    const isLastStep: boolean = useSelector(selectIsLastStep);
    const selectedRecordType: IContentType | null = useSelector(selectSelectedRecordType);
    const isConfirmed: boolean = useSelector(selectIsConfirmed);
    const errorMessages: string[] | undefined = useSelector(selectErrorMessages);
    const preventNext: boolean = useSelector(selectPreventNext);

    const selectedCorrespondenceFiles: ImsgParsedFile[] = useSelector(stateSelectedFiles);

    // const selectedRecordType: ITerm | null = useSelector(selectSelectedRecordType);
    // const selectedRecordSubType: ITerm | null = useSelector(selectSelectedSubRecordType);
    const currentFields: IContentTypeField[] | null = useSelector(selectActiveFields);
    const selectedDecisionCategory: ITerm | null = useSelector(selectSelectedDecisionCategory);
    const selectedOrgLevel1: IOrgLevel1 | null = useSelector(selectSelectedOrgLevel1);
    const selectedOrgLevel2: IOrgLevel2 | null = useSelector(selectSelectedOrgLevel2);
    const selectedOrgLevel3: IOrgLevel3 | null = useSelector(selectSelectedOrgLevel3);
    const selectedOrgLevel4: IOrgLevel4 | null = useSelector(selectSelectedOrgLevel4);
    const selectedOverrideDefaultAccess: boolean = useSelector(selectSelectedOverrideDefaultAccess);
    const selectedRestrictAccessTeams: ITerm[] | null = useSelector(
        selectSelectedRestrictAccessTeams
    );
    const selectedSecurityClassificationsType: ITerm | null = useSelector(
        selectSelectedSecurityClassificationsType
    );
    const selectedAccessRestrictedUsers: IUser[] = useSelector(selectSelectedAccessRestrictedUsers);

    const enteredTitle: string | undefined = useSelector(selectEnteredTitle);
    const enteredSummary: string | undefined = useSelector(selectEnteredSummary);
    const selectedRequestFrom: ITerm | null = useSelector(selectSelectedRequestFrom);

    const selectedDateRequested: Date | undefined = useSelector(selectDateRequested);
    const selectedTimeframe: ITerm | null = useSelector(selectSelectedTimeframe);
    const selectedSignatureRequired: boolean | undefined = useSelector(selectSignatureRequired);
    const selectedRecordFlags: ITerm[] | null = useSelector(selectSelectedRecordFlags);
    const currentFieldValues: IFieldValue[] | null = useSelector(selectCurrentFieldValues);
    const currentUserValues: IFieldValue[] | null = useSelector(selectCustomUserValues);

    const selectedInitiator: IUser | null = useSelector(selectSelectedInitiator);

    const createdRecords: IRecord[] = useSelector(selectCreatedRecords);

    const selectedTemplateCollection: ITemplate[] | null = useSelector(userSelectedTemplates);
    const uploadedDocsCollection: IBaseRecordDocument[] = useSelector(uploadedDocs);
    const fileOrder: IFileOrder = useSelector(uploadedFileOrder);

    const selectedFiles: ImsgParsedFile[] = useSelector(stateSelectedFiles);

    const { t } = useTranslation();

    const cabinetWorkingFileNumber: string | undefined = useSelector(selectCabinetWorkingFileNumber);    

    // 3. DEFINE COMPONENT STATE
    // NOTE: Use global state to define this, don't pass callbacks around!
    // NOTE: You can use selectors from other features to check any global state!
    // const [pendingCreation, setPendingCreation] = useState<boolean>(false);
    // const [errorMessage, setErrorMessage] = useState<string | null>(null);

    // 4. GET DISPATCH
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const dispatch: Dispatch<any> = useDispatch();

    // 5. DEFINE COMPONENT HOOKS
    // NOTE: No component hooks
    React.useEffect(() => {
        dispatch(setWizardKeyRolesFormMode(FormMode.New));
        dispatch(resetWizardResponseTemplateStep());
    }, [dispatch]);

    React.useEffect(() => { 
        const pageTitle: string = t("appTitleRecordWizard");
        if(pageTitle){
            dispatch(setAppTitle(pageTitle)); 
        }
    }, [currentWizardPage, dispatch, t]);

    React.useEffect(() => { return () => { resetAllRecordWizardState(dispatch); }; }, [dispatch]);
    const scrollToTop: () => void = React.useCallback(() => {
        window.scrollTo(0, 0);
    }, []);

    const beforeNextPage: () => void = React.useCallback(() => {        
        if(currentWizardPage === WizardStates.CorrespondenceItems){
            const isFilesRequired: boolean = selectedRecordType?.mandatoryCorrespondenceUpload !== false;
            const emptyFile: ImsgParsedFile = {
                name: "",
                path: "",
                subject: "",
                sentOn: "",
                receivedOn: "",
                from: undefined,
                to: [],
                uploadedName: ""
            };
            
            if (!selectedFiles?.length && !isFilesRequired) {  
                dispatch(addSelectedFile(emptyFile));  
            } 
        }
    }, [dispatch, currentWizardPage, selectedFiles, selectedRecordType]);

    React.useEffect(() => {
        if(historyListener){ historyListener.remove(); }
        const remove: () => void = history.listen((location) => {
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            if (location.hash && !(location.state && (location.state as any).skipLocationHandler)) {
                const currentStep: number = Number(history.location.hash.split(currentStepHashKey)[1]) || 0;
                const prevStep: number = Number(prevHostRelativeUrl?.split(currentStepHashKey)[1]) || 0;
                if (history.action === "POP") {
                    if (currentStep > prevStep) {
                        history.goBack();
                        return;
                    } else if (currentStep < prevStep) {
                        dispatch(previousPage());
                    }
                }
            }
            prevHostRelativeUrl = `${history.location.pathname}${history.location.hash ?? `/${history.location.hash}`}`;
        });
        prevHostRelativeUrl = `${history.location.pathname}${history.location.hash ?? `/${history.location.hash}`}`;
        historyListener = {remove};
    }, [dispatch, history]);

    // 6. RETURN RENDER TEMPLATE
    let wizardElement: JSX.Element;
    switch (currentWizardPage) {
        case WizardStates.CreateRecord:
            wizardElement = <WizardCreateRecord />;
            break;
        case WizardStates.CorrespondenceItems:
            wizardElement = <WizardCorrespondenceFiles />;
            break;
        case WizardStates.CorrespondenceDetails:
            wizardElement = <WizardCorrespondenceDetails />;
            break;
        case WizardStates.RecordInformation:
            wizardElement = <WizardRecordInformation />;
            break;
        case WizardStates.ResponseInformation:
            wizardElement = <WizardResponseInformation />;
            break;
        case WizardStates.RecordTemplate:
            wizardElement = <WizardResponseTemplateStep />;
            break;
        case WizardStates.KeyRoles:
            wizardElement = (
                <WizardKeyRolesContainer form={<WizardKeyRolesForm fields={currentFields || undefined} recordType={selectedRecordType || undefined} readOnly={false} />} />
            );
            break;
        case WizardStates.Summary:
            wizardElement = <WizardSummary />;
            break;
        case WizardStates.RecordConfirmation:
            wizardElement = <WizardRecordConfirmation />;
            break;
        default:
            wizardElement = <div>default</div>;
    }

    const getCustomValues = ():IFieldValue[] => {
        const result:IFieldValue[] = [];
        if(currentFields)
            for (const field of currentFields) {
                if(field.fieldType !== FieldTypes.Role && field.fieldType !== FieldTypes.RoleMultiSelect){
                    const fv: IFieldValue | undefined = currentFieldValues?.find(v=>v.fieldInternalName===field.internalName);
                    if(fv){
                        result.push(fv);
                    }
                } else {
                    const fv: IFieldValue | undefined = currentUserValues?.find(v=>v.fieldInternalName===field.internalName);
                    if(fv){
                        result.push(fv);
                    }
                }
            }
        
        return result;
    };

    const validateCreateRecord = (): void => {
        if (
            selectedRecordType?.rowKey
        ) {
            let recordsToCreate: ICreateRecordRequest[] = [];
            
            const recordTemplate: ICreateRecordRequest = {
                title: enteredTitle,
                recordType: selectedRecordType?.rowKey || undefined,
                decisionCategory: selectedDecisionCategory ? [selectedDecisionCategory] : undefined,
                organisationLv1: selectedOrgLevel1?.name || undefined,
                organisationLv2: selectedOrgLevel2?.name || undefined,
                organisationLv3: selectedOrgLevel3?.name || undefined,
                organisationLv4: selectedOrgLevel4?.name || undefined,
                overrideDefaultAccess: selectedOverrideDefaultAccess,
                restrictAccessTeams: selectedRestrictAccessTeams || undefined,
                securityClassification: selectedSecurityClassificationsType
                    ? [selectedSecurityClassificationsType]
                    : undefined,
                cabinetWorkingFolderNumber: cabinetWorkingFileNumber,
                fieldValues: getCustomValues(),
                viewAccessUsers: selectedAccessRestrictedUsers || undefined,
                recordFlags: selectedRecordFlags ? selectedRecordFlags : undefined,

                topicSummary: enteredSummary,
                requestFrom: selectedRequestFrom ? [selectedRequestFrom] : undefined,
                dateRequested: selectedDateRequested?.toISOString(),
                timeframe: selectedTimeframe ? [selectedTimeframe] : undefined,
                signatureRequired: selectedSignatureRequired,

                initiator: selectedInitiator ? [selectedInitiator] : undefined,

                selectedTemplates: selectedTemplateCollection || undefined,
                documents: uploadedDocsCollection,
                fileOrder: fileOrder
            };
            recordTemplate.documents = uploadedDocsCollection;

            if(selectedRecordType?.isCorrespondenceType){
                if(selectedCorrespondenceFiles?.length > 0){
                    recordsToCreate = selectedCorrespondenceFiles.map(x=>({
                        ...recordTemplate,
                        title: enteredTitle || x.subject || t("correspondenceDefaultTitle"),
                        from: x.from,
                        to: x.to,
                        dateReceived: x.receivedOn,
                        dateWritten: x.sentOn,
                        originalCorrespondence: x
                    }));
                } else{
                    recordsToCreate.push(recordTemplate);
                }
            } else{
                recordsToCreate.push(recordTemplate);
            }
            
            dispatch(createRecordsAsync(recordsToCreate, history, []));
        } else {
            dispatch(setErrorMessages([createRecordErrorMessage]));
        }
    };

    return (
        <div className={styles.createRecordContainer}>
            <Breadcrumbs pageName={breadcrumbCreateRecord} />
            <div className={styles.createRecordSection}>
                <div className={styles.formContainer}>                    
                    {wizardElement}  
                    {selectedCorrespondenceFiles?.length > 0 && 
                        currentWizardPage !== WizardStates.RecordConfirmation &&
                        currentWizardPage !== WizardStates.CreateRecord &&
                        currentWizardPage !== WizardStates.CorrespondenceItems &&
                        currentWizardPage !== WizardStates.CorrespondenceDetails && (
                        <BatchCorrespondenceRecords readOnly={true} />
                    )}              
                </div>
                <div
                    className={`${styles.createRecordFooter} 
                    ${currentWizardPage === WizardStates.RecordConfirmation && styles.noBorder}`}
                >
                    <div className={styles.leftButtonContainer}>
                        {!isLastStep && currentWizardPage !== WizardStates.Summary  && (
                            <PrimaryButton
                                text={
                                    currentWizardPage === WizardStates.CreateRecord
                                        ? wrapperGetStartedButton
                                        : wrapperNextButton
                                }
                                onClick={() => {   
                                    beforeNextPage();                               
                                    dispatch(nextPage(history));
                                    scrollToTop();
                                }}
                                allowDisabledFocus
                                disabled={preventNext}
                                ariaLabel={`${currentWizardPage === WizardStates.CreateRecord
                                    ? wrapperGetStartedButton
                                    : wrapperNextButton} button`}
                            />
                        )}
                        {currentWizardPage === WizardStates.Summary && (
                            <PrimaryButton
                                text={wrapperCreateRecordButton}
                                onClick={() => {
                                    validateCreateRecord();
                                }}
                                allowDisabledFocus
                                disabled={preventNext || !isConfirmed}
                                ariaLabel={`${wrapperCreateRecordButton} button`}
                            />
                        )}

                        {!isFirstStep && !isLastStep  && (
                            <DefaultButton
                                text={wrapperBackButton}
                                onClick={() => {
                                    history.goBack();
                                    scrollToTop();
                                }}
                                allowDisabledFocus
                                disabled={currentWizardPage === WizardStates.CreateRecord}
                                ariaLabel={`${wrapperBackButton} button`}
                            />
                        )}
                        {currentWizardPage === WizardStates.RecordConfirmation && (
                            <>
                                {createdRecords.length === 1 && 
                                    <Link
                                        to={AppRoutes.buildUrl(AppRoutes.manageRecord, {
                                            recordId: createdRecords[0].recordId || ""
                                        })}
                                    >
                                        <PrimaryButton
                                            text={wrapperOpenButton}
                                            // onClick={() =>
                                            //     dispatch(showManageRecord(createdRecord?.recordId))
                                            // }
                                            disabled={!createdRecords[0]}
                                            allowDisabledFocus
                                            ariaLabel={`${wrapperOpenButton} button`}
                                        />
                                    </Link>
                                }
                                <Link to={AppRoutes.home}>
                                    <DefaultButton
                                        text={dashboardButton}
                                        allowDisabledFocus
                                        ariaLabel={`${dashboardButton} button`}
                                    />
                                </Link>
                            </>
                        )}
                    </div>
                    {!isFirstStep && !isLastStep && (
                        <div className={styles.rightButtonContainer}>

                            <Link to={AppRoutes.home}>
                                <DefaultButton
                                    text={wrapperExitButton}
                                    allowDisabledFocus
                                    disabled={false}
                                    ariaLabel={`${wrapperExitButton} button`}
                                />
                            </Link>

                        </div>
                    )}
                </div>
                {!!errorMessages?.length && (errorMessages.map((e, idx) => (
                    <MessageBar
                        key={idx}
                        isMultiline={false}
                        messageBarType={MessageBarType.error}
                    >
                        {e}
                    </MessageBar>
                )))}
            </div>
        </div>
    );
}
