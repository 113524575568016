import IApiServiceExecutor, {
    ICreateRecordRequest,
    IDefaultKeyRoles,
    IDefaultKeyRolesRequest,
    IDeleteRecordDocumentRequest,
    IEditRecordDocumentRequest,
    IGeneratePdfPackRequest,
    IGetRecordsRequest,
    IIWantToOptions,
    IOrgs,
    IPdfPackResult,
    IRecord,
    IRecordActivities,
    IRecordDepartmentActivities,
    IRecordDocuments,
    IRecordResults,
    IReorderRecordDocumentRequest,
    ISimpleUsers,
    ITemplate,
    ITermGroup,
    IUpdateActivityLogRequest,
    IUpdateDocumentSignaturesRequest,
    IUpdateRecordRequest,
    IUpdateTaskRequest,
    IUploadRecordDocumentRequest,
    IUploadRecordSignedDocumentRequest,
    IUploadRecordTemplateRequest,
    IUsers,
    IGenerateExportPdfPackRequest,
    ICorrespondenceFile,
    IBaseFile,
    ICopyRecordRequest,
    IContact,
    IUserSettings,
    IProxyEmailTemplates,
    ISupersedeRecordRequest,
    IHeaderLink,
    IGenerateExportCsvPackRequest,
    IUserValidation,
    ContactSource,
    IBulkUpdateStateResponse,
    IStartBulkUpdateRequest,
    ICurrentClientContext,
    IUser,
    IFile,
    IGenerateOneNoteHtmlRequst,
    ITerm,
    IAppSettings,
    IContentTypeField,
    IContentType,
    ITimelineEntry,
    IDocumentSearchResponse,
    IOpenAIDocumentGenerationRequest,
    IOpenAIDocumentGenerationResponse,
    IWorkflowConfig,
    IUserFieldActivity
} from "./IApiServiceExecutor";
import IAuthService from "../../Auth/IAuthService";
import ILogProvider from "../../../providers/Log/ILogProvider";
import { AccountInfo } from "@azure/msal-common";
import { IAuthApiConfig } from "../../Auth/executor/IAuthServiceExecutor";
import { FileHelper } from "../../../helpers/FileHelper";
import UrlHelper from "../../../helpers/UrlHelper";

export default class ApiServiceExecutor implements IApiServiceExecutor {
    private logProvider: ILogProvider;
    private authService: IAuthService;

    private readonly className: string = "ApiServiceExecutor";

    constructor(logProvider: ILogProvider, authService: IAuthService) {
        this.logProvider = logProvider;
        this.authService = authService;
    }
    GetCurrentUser(): Promise<IUser | null> {
        throw new Error("Method not implemented.");
    }
    public async SearchDocuments(search: string): Promise<IDocumentSearchResponse[] | null> {
        const response: IDocumentSearchResponse[] | null = await this.SendRequest<
            IDocumentSearchResponse[]
        >("GET", "/api/SearchDocuments?search=" + search);
        return response;
    }
    public async GenerateDocumentWithAI(
        request: IOpenAIDocumentGenerationRequest
    ): Promise<IOpenAIDocumentGenerationResponse | null> {
        const body: string = JSON.stringify(request);
        const response: IOpenAIDocumentGenerationResponse | null =
            await this.SendRequest<IOpenAIDocumentGenerationResponse>(
                "POST",
                "/api/GenerateDocumentWithAI",
                body
            );
        return response;
    }

    public async ClearCache(): Promise<boolean> {
        const response: boolean | null = await this.SendRequest<boolean>("POST", "/api/ClearCache");
        return response || false;
    }

    public async UpdateRecord(updateRecordRequest: IUpdateRecordRequest): Promise<IRecord | null> {
        const body: string = JSON.stringify(updateRecordRequest);
        const response: IRecord | null = await this.SendRequest<IRecord>(
            "POST",
            "/api/UpdateRecord",
            body
        );
        return response;
    }

    public async UpdateTask(updateTaskRequest: IUpdateTaskRequest): Promise<IRecord | null> {
        const body: string = JSON.stringify(updateTaskRequest);
        const response: IRecord | null = await this.SendRequest<IRecord>(
            "POST",
            "/api/UpdateTask",
            body
        );
        return response;
    }

    public async UpdateActivityLog(
        updateActivityLogRequest: IUpdateActivityLogRequest
    ): Promise<boolean | null> {
        const body: string = JSON.stringify(updateActivityLogRequest);
        const response: boolean | null = await this.SendRequest<boolean>(
            "POST",
            "/api/UpdateActivityLog",
            body
        );
        return response;
    }

    public async UploadRecordDocument(
        uploadRecordDocumentRequest: IUploadRecordDocumentRequest
    ): Promise<string | null> {
        const body: string = JSON.stringify(uploadRecordDocumentRequest);
        const response: string | null = await this.SendRequest<string>(
            "POST",
            "/api/UploadRecordDocument",
            body
        );
        return response;
    }

    public async UploadRecordSignedDocument(
        uploadRecordSignedDocumentRequest: IUploadRecordSignedDocumentRequest
    ): Promise<IRecordDocuments | null> {
        const body: string = JSON.stringify(uploadRecordSignedDocumentRequest);
        const response: IRecordDocuments | null = await this.SendRequest<IRecordDocuments>(
            "POST",
            "/api/UploadRecordDocumentSigned",
            body
        );
        return response;
    }

    public async UploadRecordTemplate(
        uploadRecordTemplateRequest: IUploadRecordTemplateRequest
    ): Promise<boolean> {
        const body: string = JSON.stringify(uploadRecordTemplateRequest);
        const response: boolean | null = await this.SendRequest<boolean>(
            "POST",
            "/api/UploadRecordTemplate",
            body
        );
        return response || false;
    }

    public async UploadFileToPersonalStorage(request: IFile): Promise<IBaseFile | null> {
        const body: string = JSON.stringify(request);
        const response: IBaseFile | null = await this.SendRequest<IBaseFile>(
            "POST",
            "/api/UploadFileToPersonalStorage",
            body
        );
        return response;
    }

    public async UploadAndParseCorrespondenceFile(
        request: IFile
    ): Promise<ICorrespondenceFile | null> {
        const body: string = JSON.stringify(request);
        const response: ICorrespondenceFile | null = await this.SendRequest<ICorrespondenceFile>(
            "POST",
            "/api/UploadAndParseCorrespondenceFile",
            body
        );
        return response;
    }

    public async DeleteRecordDocument(
        deleteRecordDocumentRequest: IDeleteRecordDocumentRequest
    ): Promise<boolean> {
        const body: string = JSON.stringify(deleteRecordDocumentRequest);
        const response: boolean | null = await this.SendRequest<boolean>(
            "POST",
            "/api/DeleteRecordDocument",
            body
        );
        return response || false;
    }

    public async DeleteFileFromPersonalStorage(deleteRequest: IBaseFile): Promise<boolean> {
        const body: string = JSON.stringify(deleteRequest);
        const response: boolean | null = await this.SendRequest<boolean>(
            "POST",
            "/api/DeleteFileFromPersonalStorage",
            body
        );
        return response || false;
    }

    public async EditRecordDocument(
        editRecordDocumentRequest: IEditRecordDocumentRequest
    ): Promise<boolean> {
        const body: string = JSON.stringify(editRecordDocumentRequest);
        const response: boolean | null = await this.SendRequest<boolean>(
            "POST",
            "/api/RenameRecordDocument",
            body
        );
        return response || false;
    }

    public async ReorderRecordDocument(
        reorderRecordDocumentRequest: IReorderRecordDocumentRequest
    ): Promise<boolean> {
        const body: string = JSON.stringify(reorderRecordDocumentRequest);
        const response: boolean | null = await this.SendRequest<boolean>(
            "POST",
            "/api/ReorderRecordDocument",
            body
        );
        return response || false;
    }

    public async CreateRecord(createRecordRequest: ICreateRecordRequest): Promise<IRecord | null> {
        const body: string = JSON.stringify(createRecordRequest);
        const response: IRecord | null = await this.SendRequest<IRecord>(
            "POST",
            "/api/CreateRecord",
            body
        );
        return response;
    }

    public async CopyRecord(copyRecordRequest: ICopyRecordRequest): Promise<IRecord | null> {
        const body: string = JSON.stringify(copyRecordRequest);
        const response: IRecord | null = await this.SendRequest<IRecord>(
            "POST",
            "/api/CopyRecord",
            body
        );
        return response;
    }

    public async SupersedeRecord(
        supersedeRecordRequest: ISupersedeRecordRequest
    ): Promise<IRecord | null> {
        const body: string = JSON.stringify(supersedeRecordRequest);
        const response: IRecord | null = await this.SendRequest<IRecord>(
            "POST",
            "/api/SupersedeRecord",
            body
        );
        return response;
    }

    public async ValidateUserAccess(): Promise<IUserValidation | null> {
        const response: IUserValidation | null = await this.SendRequest<IUserValidation>(
            "GET",
            "/api/ValidateUserAccess"
        );
        return response;
    }

    public async GetCurrentContext(): Promise<ICurrentClientContext | null> {
        const response: ICurrentClientContext | null =
            await this.SendRequest<ICurrentClientContext>("GET", "/api/GetCurrentContext");
        return response;
    }

    public async GetHeaderLinks(): Promise<IHeaderLink[] | null> {
        const response: IHeaderLink[] | null = await this.SendRequest<IHeaderLink[]>(
            "GET",
            "/api/GetHeaderLinks"
        );
        return response;
    } 

    public async GetUsers(searchTerm: string): Promise<ISimpleUsers | null> {
        const response: IUsers | null = await this.SendRequest<IUsers>(
            "GET",
            `/api/GetUsers?q=${searchTerm}`
        );
        return response;
    }

    public async GetAllTermSets(): Promise<ITermGroup | null> {
        const response: ITermGroup | null = await this.SendRequest<ITermGroup>(
            "GET",
            "/api/GetAllTermSets"
        );
        return response;
    }

    public async GetContentTypesFieldsSchema(): Promise<string> {
        const response: string | null = await this.SendRequest<string>(
            "GET",
            "/api/GetContentTypeFields?schemaexport"
        );
        return response || "";
    }

    public async GetWorkflowDiagram(recordTypeName: string): Promise<string | null> {
        const response: string | null = await this.SendRequest<string>(
            "GET",
            "/api/GetWorkflowDiagram?rt=" + recordTypeName,
            undefined,
            undefined,
            undefined,
            true
        );
        return response;
    }

    public async GetWorkflowConfig(): Promise<string | null> {
        const response: string | null = await this.SendRequest<string>(
            "GET",
            "/api/GetWorkflowConfig"
        );
        return response ? JSON.stringify(response) : null;
    }

    public async SetWorkflowConfig(workflowConfig: IWorkflowConfig): Promise<boolean> {
        const response: boolean | null = await this.SendRequest<boolean>(
            "POST",
            "/api/SetWorkflowConfig",
            JSON.stringify(workflowConfig)
        );
        return response ? true : false;
    }

    public async GetContentTypeFields(
        contentTypeName: string
    ): Promise<IContentTypeField[] | null> {
        const response: IContentTypeField[] | null = await this.SendRequest<IContentTypeField[]>(
            "GET",
            "/api/GetContentTypeFields?ct=" + contentTypeName
        );
        return response;
    }

    public async GetContentTypes(): Promise<IContentType[] | null> {
        const response: IContentType[] | null = await this.SendRequest<IContentType[]>(
            "GET",
            "/api/GetContentTypes"
        );
        return response;
    }

    public async GetContentTypesSchema(): Promise<string> {
        const response: string | null = await this.SendRequest<string>(
            "GET",
            "/api/GetContentTypes?schemaexport"
        );
        return response || "";
    }

    public async GetOrgHierarchy(): Promise<IOrgs | null> {
        const response: IOrgs | null = await this.SendRequest<IOrgs>("GET", "/api/GetOrgHierarchy");
        return response;
    }

    public async GetRecord(recordId: string, includeTimelineAndMenu: boolean): Promise<IRecord | null> {
        const response: IRecord | null = await this.SendRequest<IRecord>(
            "GET",
            `/api/GetRecord?r=${recordId}&incl=${includeTimelineAndMenu}`
        );
        return response;
    }

    public async GetTimelineEntries(recordId: string): Promise<ITimelineEntry[] | null> {
        const response: ITimelineEntry[] | null = await this.SendRequest<ITimelineEntry[]>(
            "GET",
            `/api/GetTimelineEntries?r=${recordId}`
        );
        return response;
    }

    public async GetRecords(getRecordsRequest: IGetRecordsRequest): Promise<IRecordResults | null> {
        const body: string = JSON.stringify(getRecordsRequest);
        const response: IRecordResults | null = await this.SendRequest<IRecordResults>(
            "POST",
            "/api/GetRecords",
            body
        );
        return response;
    }

    public async GetDefaultKeyRoles(
        req: IDefaultKeyRolesRequest
    ): Promise<IDefaultKeyRoles | null> {
        const response: IDefaultKeyRoles | null = await this.SendRequest<IDefaultKeyRoles>(
            "GET",
            "/api/GetDefaultKeyRoles" +
                `?rt=${encodeURIComponent(req.recordType || "")}` +
                `&dc=${encodeURIComponent(req.decisionCategory?.name || "")}` +
                `&o1=${encodeURIComponent(req.organisationLv1 || "")}` +
                `&o2=${encodeURIComponent(req.organisationLv2 || "")}` +
                `&o3=${encodeURIComponent(req.organisationLv3 || "")}` +
                `&o4=${encodeURIComponent(req.organisationLv4 || "")}`
        );
        return response;
    }

    public async GetAvailableTemplates(recordType: string): Promise<ITemplate[] | null> {
        type ITemplates = { templates: ITemplate[] };
        const response: ITemplates | null = await this.SendRequest<ITemplates>(
            "GET",
            `/api/GetAvailableTemplates?rt=${encodeURIComponent(recordType || "")}`
        );
        return response?.templates || [];
    }

    public async GetAvailableRecordFlags(recordType: string): Promise<ITerm[] | null> {
        const response: ITerm[] | null = await this.SendRequest<ITerm[]>(
            "GET",
            `/api/GetAvailableRecordFlags?rt=${encodeURIComponent(recordType || "")}`
        );
        return response;
    }

    public async GetIWantToOptions(recordId: string): Promise<IIWantToOptions | null> {
        const response: IIWantToOptions | null = await this.SendRequest<IIWantToOptions>(
            "GET",
            `/api/GetIWantToOptions?r=${recordId}`
        );
        return response;
    }

    public async GetRecordDocuments(
        recordId: string,
        recordName: string
    ): Promise<IRecordDocuments | null> {
        const response: IRecordDocuments | null = await this.SendRequest<IRecordDocuments>(
            "GET",
            `/api/GetRecordDocuments?rId=${recordId}&rName=${recordName}`
        );
        return response;
    }

    public async GetContacts(
        query?: string,
        types?: ContactSource[],
        top?: number
    ): Promise<IContact[]> {
        const response: IContact[] | null = await this.SendRequest<IContact[]>(
            "GET",
            `/api/GetContacts?q=${query || ""}${!types?.length ? "" : `&types=${types.join(";")}`}${
                !top ? "" : "&top=" + top
            }`
        );
        return response || [];
    }

    public async UpdateCreateContact(contact: IContact): Promise<IContact | null> {
        const body: string = JSON.stringify(contact);
        const response: IContact | null = await this.SendRequest<IContact>(
            "POST",
            "/api/UpdateCreateContact",
            body
        );
        return response;
    }

    public async UpdateDocumentSignatures(
        UpdateDocumentSignatures: IUpdateDocumentSignaturesRequest
    ): Promise<boolean> {
        const body: string = JSON.stringify(UpdateDocumentSignatures);
        const response: boolean | null = await this.SendRequest<boolean>(
            "POST",
            "/api/UpdateDocumentSignatures",
            body
        );
        return !!response;
    }

    public async GenerateWatermarkedPDF(filePath: string): Promise<string | null> {
        const response: string | null = await this.SendRequest<string>(
            "GET",
            `/api/GetWatermarkedDocument?fileRef=${filePath}`,
            undefined,
            true,
            UrlHelper.getFilenameFromUrl(decodeURIComponent(filePath))
        );
        return response;
    }

    public async GeneratePdfPack(
        pdfPackRequest: IGeneratePdfPackRequest
    ): Promise<IPdfPackResult | null> {
        const response: IPdfPackResult | null = await this.SendRequest<IPdfPackResult>(
            "GET",
            `/api/GeneratePdfPack?rId=${pdfPackRequest.recordId}&rName=${pdfPackRequest.title}`
        );
        return response;
    }

    public async GenerateExportPdfPack(
        pdfPackRequest: IGenerateExportPdfPackRequest
    ): Promise<string | null> {
        const body: string = JSON.stringify(pdfPackRequest);
        const response: string | null = await this.SendRequest<string>(
            "POST",
            "/api/GenerateExportPdfPack",
            body
        );
        return response;
    }

    public async GenerateOneNoteHtml(
        generateOneNoteHtmlRequst: IGenerateOneNoteHtmlRequst
    ): Promise<string | null> {
        const response: string | null = await this.SendRequest<string>(
            "GET",
            `/api/GenerateOneNoteHtml?rId=${generateOneNoteHtmlRequst.recordId}&rName=${generateOneNoteHtmlRequst.title}`
        );

        return response;
    }

    public async GenerateExportCsvPack(
        csvPackRequest: IGenerateExportCsvPackRequest
    ): Promise<IBaseFile | null> {
        const body: string = JSON.stringify(csvPackRequest);
        const response: IBaseFile | null = await this.SendRequest<IBaseFile>(
            "POST",
            "/api/GenerateExportCsvPack",
            body
        );
        return response;
    }

    public async GetRecordDepartmentActivity(
        recordId: string
    ): Promise<IRecordDepartmentActivities | null> {
        const response: IRecordDepartmentActivities | null =
            await this.SendRequest<IRecordDepartmentActivities>(
                "GET",
                `/api/GetRecordDepartmentActivity?r=${recordId}`
            );
        return response;
    }

    public async GetAccessRestrictedUsersHistory(recordId: string): Promise<IUserFieldActivity[] | null> {
        const response: IUserFieldActivity[] | null = await this.SendRequest<IUserFieldActivity[]>(
            "GET",
            `/api/GetAccessRestrictedUsersHistory?r=${recordId}`
        );
        return response;
    }

    public async GetRecordActivityLog(recordId: string): Promise<IRecordActivities | null> {
        const response: IRecordActivities | null = await this.SendRequest<IRecordActivities>(
            "GET",
            `/api/GetRecordActivity?r=${recordId}`
        );
        return response;
    }

    public async GetRecordActivityCsv(recordId: string): Promise<IBaseFile | null> {
        const response: IBaseFile | null = await this.SendRequest<IBaseFile | null>(
            "GET",
            `/api/GetRecordActivityCsv?r=${recordId}`
        );
        return response;
    }

    public async UpdateUserSettings(userSettings: IUserSettings): Promise<boolean> {
        const body: string = JSON.stringify(userSettings);
        const response: string | null = await this.SendRequest<string>(
            "POST",
            "/api/UpdateUserSettings",
            body
        );
        return response == null ? false : true;
    }

    public async GetUserSettings(subScopeId?: string): Promise<IUserSettings | null> {
        const response: IUserSettings | null = await this.SendRequest<IUserSettings>(
            "GET",
            `/api/GetUserSettings${subScopeId ? `?subScopeId=${subScopeId}` : ""}`
        );
        return response;
    }

    public async GetUserPhoto(userPrincipal: string): Promise<string | null> {
        const response: string | null = await this.SendRequest<string>(
            "GET",
            `/api/GetUserImage?userPrincipal=${userPrincipal}`
        );
        return response;
    }

    public async GetPublicHolidays(): Promise<Date[] | null> {
        const response: Date[] | null = await this.SendRequest<Date[]>(
            "GET",
            "/api/GetPublicHolidayDates"
        );

        return response;
    }

    public async GetBulkUpdateState(): Promise<IBulkUpdateStateResponse | null> {
        const response: IBulkUpdateStateResponse | null =
            await this.SendRequest<IBulkUpdateStateResponse>("GET", "/api/GetBulkUpdateState");

        return response;
    }
    public async StartBulkUpdate(
        model: IStartBulkUpdateRequest
    ): Promise<IBulkUpdateStateResponse | null> {
        const body: string = JSON.stringify(model);
        const response: IBulkUpdateStateResponse | null =
            await this.SendRequest<IBulkUpdateStateResponse>("POST", "/api/StartBulkUpdate", body);

        return response;
    }

    public async GetApiBaseUrl(): Promise<string> {
        const apiConfig: IAuthApiConfig = await this.authService.GetAuthApiConfig();
        return apiConfig.baseUrl;
    }

    public async GetProxyEmailTemplates(): Promise<IProxyEmailTemplates | null> {
        const response: IProxyEmailTemplates | null = await this.SendRequest<IProxyEmailTemplates>(
            "GET",
            "/api/GetProxyEmailTemplates"
        );
        return response;
    }

    public async GetAppSettings(): Promise<IAppSettings | null> {
        const response: IAppSettings | null = await this.SendRequest<IAppSettings>(
            "GET",
            "/api/GetAppListSettings"
        );
        return response;
    }

    public async UpdateAppSettings(appSettings: IAppSettings): Promise<boolean | string> {
        const body: string = JSON.stringify(appSettings);
        const response: boolean | string | null = await this.SendRequest<boolean | string>(
            "POST",
            "/api/UpdateAppListSettings",
            body
        );
        return response ?? false;
    }

    private async SendRequest<outT>(
        method: "GET" | "POST",
        endpoint: string,
        body?: string,
        fileResponce?: boolean,
        fileName?: string,
        throwOnFail?: boolean
    ): Promise<outT | null> {
        const accessToken: string = await this.authService.GetAccessToken();
        const baseUrl: string = await this.GetApiBaseUrl();

        const headers: Headers = new Headers();
        const bearer: string = "Bearer " + accessToken;
        headers.append("Authorization", bearer);

        const host: string = window.location.host.toLowerCase();
        if (host.indexOf("localhost") === 0) {
            const account: AccountInfo | null = await this.authService.SignIn();
            const upn: string | undefined = account?.username;
            if (upn) {
                headers.append("UPN", upn);
            }
        }

        let json: outT | null = null;
        try {
            const r: Response = await fetch(`${baseUrl}${endpoint}`, {
                method: method,
                headers: headers,
                body: body
            });
            if (r.ok) {
                if (fileResponce) {
                    const blob: Blob = await r.blob();
                    FileHelper.downloadBlob(blob, fileName || "file.pdf");
                    const blobToBase64 = (blobInpt: Blob): Promise<outT | null> => {
                        return new Promise(resolve => {
                            const reader: FileReader = new FileReader();
                            // eslint-disable-next-line @typescript-eslint/no-explicit-any
                            reader.onloadend = () => resolve(reader.result as any);
                            reader.readAsDataURL(blobInpt);
                        });
                    };
                    json = await blobToBase64(blob);
                } else {
                    json = await r.json();
                }
            } else {
                this.logProvider.Error(
                    this.className,
                    `Failed to SendRequest: ${method} ${endpoint}`,
                    JSON.stringify(r)
                );
                if (fileResponce) {
                    throw new Error(JSON.stringify(r.statusText));
                }
                const errorText: string = await r.text();
                this.logProvider.Error(
                    this.className,
                    `Error text for SendRequest: ${method} ${endpoint}`,
                    errorText
                );
                if (throwOnFail) {
                    const error: Error = new Error(errorText || r.statusText);
                    error.name = r.statusText;
                    throw error;
                }
            }
        } catch (ex) {
            this.logProvider.Error(
                this.className,
                `Failed to SendRequest: ${method} ${endpoint}`,
                JSON.stringify(ex)
            );
            if (fileResponce || throwOnFail) {
                throw ex;
            }
        }

        // TODO: Retry on transient errors?

        return json;
    }
}
